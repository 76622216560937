import { Notifications } from '../api';
import { SendNotificationPayload } from './types';

interface SendNotificationProps {
  jwtToken: string;
  payload: SendNotificationPayload;
}

export const sendNotification = async ({ jwtToken, payload }: SendNotificationProps) => {
  const modelKeys = ['tutors', 'teachers', 'students', 'staff'] as const;
  const overrideModelIds: Record<string, (string | number)[]> = {};

  let singleElementsCount = 0;
  modelKeys
    .filter((k) => payload[k])
    .map((k) => {
      const ids = payload[k];
      singleElementsCount += ids.length;
      overrideModelIds[k] = ids.reduce(
        (acc, e) => [...acc, e.contentType, e.objectId],
        [] as (string | number)[],
      );
    });

  // this sucks but it's a backend requirement
  const files = [];
  for (let i = 0; i < singleElementsCount; i++) {
    files.push(payload.fileKeys);
  }

  const specificStudentListForSectionsAndOfficialSections = (
    payload.specificStudentListForSectionsAndOfficialSections || []
  ).map((e) => [e.contentType, e.objectId, e.studentIds]);

  return await Notifications.custom.sendNotification({
    token: jwtToken,
    data: {
      ...payload,
      ...overrideModelIds,
      // TODO: implement a snake case parsin for the data body in the api utility
      specific_student_list_for_sections_and_official_sections:
        specificStudentListForSectionsAndOfficialSections, // overide payload with specificStudentListForSectionsAndOfficialSections
      file_keys: files,
    },
  });
};
