import React from 'react';
import Icon from '../Icon';
import { v4 as uuidv4 } from 'uuid';
import { SheetHeader } from '../../data/types';
import styles from './summaryTable.module.scss';

type ImporterDataFieldError = {
  errors: any | undefined;
  index: number;
};

type ImporterSummaryTablePropsType = {
  headers: SheetHeader[] | undefined;
  data: any[] | undefined;
  details?: ImporterDataFieldError[] | undefined;
};

type DummyErrorElementPropTypes = {
  field: string;
  description: string;
};

const DummyErrorElement: React.FunctionComponent<DummyErrorElementPropTypes> = ({
  field,
  description,
}: DummyErrorElementPropTypes) => (
  <li key={uuidv4()}>
    <strong>{`${field}: `}</strong>
    {description}
  </li>
);

const ImporterSummaryTable: React.FunctionComponent<ImporterSummaryTablePropsType> = ({
  headers,
  data,
  details,
}: ImporterSummaryTablePropsType) => {
  const getDetailsByIndex = (idx: number) => {
    return details?.filter(({ index }) => index === idx)[0];
  };

  const isInvalidHeadersError = () => {
    return details && details.length > 0 && typeof details[0] === 'string';
  };

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th key={uuidv4()} className={styles.header}>
            <Icon iconName={'check'} />
          </th>
          {headers &&
            headers.map(({ label }) => (
              <th key={uuidv4()} className={styles.header}>
                {label}
              </th>
            ))}
        </tr>
      </thead>
      <tbody>
        {data &&
          headers &&
          data.map((val, idx) => (
            <tr key={uuidv4()} className={styles.row}>
              {details?.find(({ index }) => index === idx) && getDetailsByIndex(idx) ? (
                <td>
                  <div className={styles.errorMark}>
                    <Icon iconName={'exclamationCircle'} />
                    <div className={styles.toolTip}>
                      <ul>
                        {Object.keys(getDetailsByIndex(idx)?.errors).reduce(
                          (accumulated: any[], fieldName) => {
                            accumulated.push(
                              <DummyErrorElement
                                field={fieldName}
                                description={getDetailsByIndex(idx)?.errors[fieldName]}
                              />,
                            );
                            return accumulated;
                          },
                          [],
                        )}
                      </ul>
                    </div>
                  </div>
                </td>
              ) : isInvalidHeadersError() ? (
                <td>
                  <div className={styles.errorMark}>
                    <Icon iconName={'exclamationCircle'} />
                  </div>
                </td>
              ) : (
                <td>
                  <div className={styles.noError}>
                    <Icon iconName={'checkCircle'} />
                  </div>
                </td>
              )}
              {headers.map(({ label }) => (
                <td key={uuidv4()}>{val[label] ? val[label] : ''}</td>
              ))}
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default ImporterSummaryTable;
