import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { GlobalEdooContext } from '../../../data/globalEdooContext';
import { ContextType, CycleType } from '../../../data/types';
import { useEdooImporter } from '../../../data/hooks/useEdooImporter';
import { EdooImporterSteps } from '../../../settings';
import { fetchCycles } from '../../../adapters/cycle';

import { Select, SelectOption } from '@nahualventure/paper-ui';
import ImporterStep, { FormStepProps } from '../ImporterStep';
import styles from './preparations.module.scss';

const ImporterDataPreparations: React.FunctionComponent<FormStepProps> = ({
  isActive = false,
  isDisplaying = false,
}: FormStepProps) => {
  const [cycles, setCycles] = useState<CycleType[]>([]);
  const [cycleOptions, setCycleOptions] = useState<SelectOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<SelectOption | undefined>(undefined);
  const { session } = useContext<ContextType>(GlobalEdooContext);
  const { t } = useTranslation();
  const { step, setSelectedCycle, setStep } = useEdooImporter();

  useEffect(() => {
    const fetchData = async () => {
      if (!session) return;
      const jwtToken = session!.token;

      // fetch data from a url endpoint
      const response = await fetchCycles(jwtToken).catch(() => setCycles([]));
      if (!!response) {
        const rawCycles: CycleType[] = response;
        const activeCycles: SelectOption[] = rawCycles.map((cycle) => {
          return {
            value: cycle.id,
            label: cycle.name,
          };
        });
        setCycles(rawCycles);
        setCycleOptions(activeCycles);
      }
    };

    fetchData();
  }, [session]);

  return (
    <ImporterStep
      isActive={isActive}
      isDisplaying={isDisplaying}
      title={t('EdooImporter.dataPrepare')}
      showNextButton={selectedOption && step === EdooImporterSteps.Cycle}
      onNextStep={() => setStep && setStep(EdooImporterSteps.Cycle + 1)}
    >
      <span className={styles.description}>
        <Trans i18nKey="EdooImporter.preparationsDescription">
          To load students you must have previously created the <strong>Levels</strong>,{' '}
          <strong>Courses</strong>, <strong>Sections</strong> and <strong>Cycles</strong> necessary.
        </Trans>
      </span>
      <div className={styles.cycleContent}>
        <span className={styles.cycleTitle}>{t('EdooImporter.assignCycle')}</span>
        <div className={styles.options}>
          <Select
            options={cycleOptions}
            placeholder={t('EdooImporter.selectCycle')}
            size="medium"
            value={selectedOption}
            onChange={(option: SelectOption, index: number) => {
              setSelectedOption(option);
              option && setSelectedCycle && setSelectedCycle(cycles[index]);
            }}
          />
        </div>
      </div>
    </ImporterStep>
  );
};

export default ImporterDataPreparations;
