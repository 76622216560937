import React, { useEffect, useState, useContext } from 'react';
import * as XLSX from 'xlsx';
import zipObject from 'lodash/zipObject';
import { GlobalEdooContext } from '../../../data/globalEdooContext';
import { ContextType, SheetHeader } from '../../../data/types';
import Icon from '../../Icon';
import { useTranslation } from 'react-i18next';
import { useEdooImporter } from '../../../data/hooks/useEdooImporter';
import { Button } from '@nahualventure/paper-ui';
import { fetchSheetHeadersForBulkUpload } from '../../../adapters/studentProfiles';
import { SheetHeaderBulkUploadResponse } from '../../../adapters/types';

import styles from './templateDownloadButton.module.scss';

const TemplateDownloadButton: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { setTemplateHeaders } = useEdooImporter();
  const { session } = useContext<ContextType>(GlobalEdooContext);
  const [sheetHeaders, setSheetHeaders] = useState<SheetHeader[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      if (!session) return;
      const jwtToken = session!.token;

      const response = await fetchSheetHeadersForBulkUpload(jwtToken).catch(() =>
        setSheetHeaders([]),
      );
      if (!!response) {
        const rawHeaders: SheetHeaderBulkUploadResponse = response;
        setSheetHeaders(rawHeaders.headers);
        setTemplateHeaders && setTemplateHeaders(rawHeaders.headers);
      }
    };

    fetchData();
  }, [session]);

  const onGenerateXLSXTemplate = () => {
    const outputSheet = 'bulk_load_sheet';
    const outputFile = 'importer_bulk_template.xlsx';
    const labelHeaders: string[] = sheetHeaders.map(({ label }) => label);

    // Empty Book
    const book = XLSX.utils.book_new();
    const sheet = XLSX.utils.json_to_sheet(
      [
        {
          ...zipObject(labelHeaders, labelHeaders),
        },
      ],
      { skipHeader: true },
    );
    XLSX.utils.book_append_sheet(book, sheet, outputSheet);
    XLSX.writeFile(book, outputFile);
  };

  return (
    <div className={styles.buttons}>
      <Button
        className={styles.downloadButton}
        color="gray"
        colorVariant="main"
        size="medium"
        variant="default"
        startIcon={<Icon iconSize={'lg'} tint={'label'} iconName={'cloudDownload'} />}
        onClick={() => onGenerateXLSXTemplate()}
      >
        {t('EdooImporter.templateDownload')}
      </Button>
    </div>
  );
};

export default TemplateDownloadButton;
