import React, { createContext, Context, useState } from 'react';
import { EdooImporterSteps } from '../../settings';
import {
  EdooImporterContextType,
  GlobalEdooProviderPropsType,
  CycleType,
  SheetHeader,
} from '../types';

const DEFAULT_IMPORTER_STEP = EdooImporterSteps.DataType;
const EdooImporterContext: Context<EdooImporterContextType> = createContext({});

// Reference if you prefer the traditional way to do this with
// useReducer and manage State & Types like Redux: https://kentcdodds.com/blog/how-to-use-react-context-effectively

const EdooImporterProvider: React.FunctionComponent<GlobalEdooProviderPropsType> = (
  props: GlobalEdooProviderPropsType,
) => {
  const [step, setStep] = useState<number>(DEFAULT_IMPORTER_STEP);
  const [dataType, setDataType] = useState<string>('');
  const [selectedCycle, setSelectedCycle] = useState<CycleType | undefined>(undefined);
  const [fileData, setFileData] = useState<any[] | undefined>(undefined);
  const [templateHeaders, setTemplateHeaders] = useState<SheetHeader[]>([]);

  return (
    <EdooImporterContext.Provider
      value={{
        step,
        setStep,
        dataType,
        fileData,
        templateHeaders,
        setDataType,
        selectedCycle,
        setSelectedCycle,
        setFileData,
        setTemplateHeaders,
      }}
      {...props}
    />
  );
};

function useEdooImporter(): EdooImporterContextType {
  const context = React.useContext(EdooImporterContext);
  if (context === undefined) {
    throw new Error('useEdooImporter must be used within a EdooImporterProvider');
  }
  return context;
}
export { EdooImporterProvider, useEdooImporter };
