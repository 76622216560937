import { OfficialSections } from '../api';
import { MinimalStudent } from './types';

interface GetOfficialSectionStudentsProps {
  jwtToken: string;
  id: number | string;
}

export const getOfficialSectionStudents = async ({
  jwtToken,
  id,
}: GetOfficialSectionStudentsProps): Promise<MinimalStudent[]> => {
  return (
    await OfficialSections.custom.getStudents({
      token: jwtToken,
      urlPartParams: { id },
    })
  ).students;
};
