import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@nahualventure/paper-ui';
import Icon from '../../Icon';

import styles from './step.module.scss';

export type FormStepProps = {
  isDisplaying?: boolean;
  isActive?: boolean;
  children?: React.ReactNode;
};

type ImporterStepProps = {
  title?: string;
  isActive?: boolean;
  isDisplaying?: boolean;
  isInline?: boolean;
  showNextButton?: boolean;
  nextButtonTitle?: string;
  onNextStep?: () => void;
  children: React.ReactNode;
};

const ImporterStep: React.FunctionComponent<ImporterStepProps> = ({
  title,
  isActive = false,
  isDisplaying = false,
  isInline = false,
  nextButtonTitle,
  showNextButton,
  onNextStep,
  children,
}: ImporterStepProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.stepContainer}>
        <div className={styles.titleContainer}>
          <div className={styles.icon}>
            {isDisplaying ? (
              <Icon iconSize={'lg'} tint={'primary'} iconName={'checkCircle'} />
            ) : (
              <Icon iconSize={'lg'} tint={'disabled'} iconName={'circle'} />
            )}
          </div>
          <span
            className={`
            ${styles.title}
            ${isDisplaying ? styles.display : ''}
            ${isActive ? styles.active : ''}
          `}
          >
            {title}
          </span>
        </div>
        {isInline && isDisplaying && (
          <div
            className={`
              ${styles.content}
              ${isInline ? styles.inline : ''}
            `}
          >
            {children}
          </div>
        )}
      </div>
      {!isInline && isDisplaying && <div className={styles.content}>{children}</div>}
      {showNextButton && (
        <div className={styles.nextStepButton}>
          <Button onClick={onNextStep}>
            {nextButtonTitle && nextButtonTitle !== ''
              ? nextButtonTitle
              : t('EdooImporter.nextStep')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default ImporterStep;
