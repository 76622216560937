import React from 'react';
import { useTranslation } from 'react-i18next';
import ImporterStep, { FormStepProps } from '../ImporterStep';
import BulkUploadingData from '../../BulkUploadingData';
import { useEdooImporter } from '../../../data/hooks/useEdooImporter';
import { EdooImporterSteps } from '../../../settings';

const ImporterFileUpload: React.FunctionComponent<FormStepProps> = ({
  isActive = false,
  isDisplaying = false,
}: FormStepProps) => {
  const { t } = useTranslation();
  const { step, fileData, setStep } = useEdooImporter();

  return (
    <ImporterStep
      isActive={isActive}
      isDisplaying={isDisplaying}
      title={t('EdooImporter.uploadCSV')}
      showNextButton={fileData && step === EdooImporterSteps.UploadFile}
      onNextStep={() => setStep && setStep(EdooImporterSteps.UploadFile + 1)}
    >
      <BulkUploadingData />
    </ImporterStep>
  );
};

export default ImporterFileUpload;
