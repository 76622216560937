import { Loader, Select, SelectOption } from '@nahualventure/paper-ui';
import React, { memo, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { sendNotification } from '../../adapters/notifications';
import { GlobalEdooContext } from '../../data/globalEdooContext';
import { ContextType } from '../../data/types';
import Group, { GroupTypesRecord } from './Group';
import { GroupTypes, SearchTypeahead, SearcherResponse } from './Search';
import { SelectedFile } from './SelectedFile';
import styles from './notificationForm.module.scss';
import { MinimalStudent } from '../../adapters/types';
import { getOfficialSectionStudents } from '../../adapters/officialSections';
import { getSectionStudents } from '../../adapters/sections';
import StudentList from './StudentList';
import { generateUploadUrl } from '../../adapters/files';
import { uploadFile } from '../../utils/upload-file';
import { shoutSuccess } from '../../utils';
import Icon from '../Icon';

interface UploadedFile {
  uuid: string;
  file: File;
  isLoading: boolean;
  progress: number;
  done: boolean;
}

const searchOptions: SelectOption[] = [
  {
    value: 'structure',
    label: (
      <label className={`${styles['search-option']}`}>
        <Icon iconName="sitemap" />
        <span>
          <Trans i18nKey="SidePanel.searchByStructure" />
        </span>
      </label>
    ),
  },
  {
    value: 'person',
    label: (
      <label className={`${styles['search-option']}`}>
        <Icon iconName="user" />
        <span>
          <Trans i18nKey="SidePanel.searchByPerson" />
        </span>
      </label>
    ),
  },
];

const GROUP_TYPES_SHOW_OPTIONS = {
  school: { parents: true, staff: true, students: true, teachers: true },
  structure: { parents: true, students: true, teachers: true }, // stage, level, section or official section
  person: { parents: true, students: true }, // student or tutor
  staff: {}, // staff, admin or teacher
};

const genShowGroupTypesConfiguration = (groupType: GroupTypes) => {
  return GROUP_TYPES_SHOW_OPTIONS[groupType] || {};
};

type Groups = SearcherResponse & { selectedGroupTypes: GroupTypesRecord };

export const NotificationForm: React.FunctionComponent = () => {
  const { t: translation } = useTranslation();
  const { session } = useContext<ContextType>(GlobalEdooContext);

  const genContentTypeKey = (contentType: number, objectId: number) => `${contentType}-${objectId}`;
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [selectedFiles, setSelectedFiles] = useState<UploadedFile[]>([]);
  const [selectedSearchType, setSelectedSearchType] = useState<SelectOption>(searchOptions[0]);
  const [groupsToSendNotifications, setGroupsToSendNotifications] = useState<Groups[]>([]);
  const [formIsReadyToSend, setFormIsReadyToSend] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [studentsByContentType, setStudentsByContentType] = useState<
    Record<string, MinimalStudent[]>
  >({});
  const [specificStudentsByContentType, setSpecificStudentsByContentType] = useState<
    Record<string, number[]>
  >({});
  const [showStudentListByContentType, setShowStudentListByContentType] = useState<
    Record<string, boolean>
  >({});

  // Fetch students when a group is added to the list of groups to send notifications
  useEffect(() => {
    groupsToSendNotifications.forEach((group) => {
      const key = genContentTypeKey(group.contentType, group.value);
      if (!studentsByContentType[key]) {
        const fetchStudents = async () => {
          if (!session?.token) {
            return [];
          }
          const students =
            group.internalType === 'official-section'
              ? await getOfficialSectionStudents({ jwtToken: session.token, id: group.value })
              : await getSectionStudents({ jwtToken: session.token, id: group.value });

          setStudentsByContentType((prev) => ({ ...prev, [key]: students }));
          setSpecificStudentsByContentType((prev) => ({
            ...prev,
            [key]: students.map((s) => s.id),
          }));
          return students;
        };
        fetchStudents();
      }
    });
  }, [groupsToSendNotifications, session?.token]);

  useEffect(() => {
    // check if the form is ready to send
    const hasSomeGroupSelected =
      groupsToSendNotifications.filter((g) => {
        const { parents, staff, student, students, teachers } = g.selectedGroupTypes;
        return parents || staff || student || students || teachers;
      }).length > 0;
    setFormIsReadyToSend(hasSomeGroupSelected && !!title && !!description);
  }, [groupsToSendNotifications, description, title]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      console.log(event.target.files);
      const files: FileList = event.target.files;
      const filesWithUUID: UploadedFile[] = Array.from(files).map((file: File) => ({
        uuid: uuidv4(),
        file,
        isLoading: false,
        progress: 0,
        done: false,
      }));
      setSelectedFiles((prev) => [...prev, ...filesWithUUID]);
      event.target.value = '';
    }
  };

  const handleRemoveFile = ({ uuid }: { uuid: string }) => {
    const updatedFiles = selectedFiles.filter((file) => file.uuid !== uuid);
    setSelectedFiles(updatedFiles);
  };

  const handleFileInputClick = () => {
    if (fileInputRef.current) fileInputRef.current.click();
  };

  // const handleUpload = () => {
  //   if (selectedFiles.length > 0) {
  //     console.log('Archivos seleccionados:', selectedFiles);
  //   } else {
  //     console.log('No se han seleccionado archivos.');
  //   }
  // };

  const clearState = () => {
    setTitle('');
    setDescription('');
    setSelectedFiles([]);
    setGroupsToSendNotifications([]);
    setStudentsByContentType({});
    setSpecificStudentsByContentType({});
    setShowStudentListByContentType({});
  };

  const handleOnSubmit = async () => {
    if (!formIsReadyToSend || !session?.token) return;

    setIsSending(true);

    const groups = groupsToSendNotifications.reduce((acc, group) => {
      const { parents, staff, student, students, teachers } = group.selectedGroupTypes;
      const modelIds = {
        contentType: group.contentType,
        objectId: group.value,
      };
      const groups = {
        parents: parents ? modelIds : undefined,
        staff: staff ? modelIds : undefined,
        students: students || student ? modelIds : undefined,
        teachers: teachers ? modelIds : undefined,
      };

      Object.keys(groups).forEach((key) => {
        const value = groups[key as keyof typeof groups];
        if (value) acc[key] = acc[key] ? [...acc[key], value] : [value];
      });

      return acc;
    }, {} as { [key: string]: { contentType: string | number; objectId: string | number }[] });

    const specificStudentListForSectionsAndOfficialSections = groupsToSendNotifications
      .filter((g) => {
        const key = genContentTypeKey(g.contentType, g.value);
        return specificStudentsByContentType[key] && specificStudentsByContentType[key].length > 0;
      })
      .map((g) => ({
        contentType: g.contentType,
        objectId: g.value,
        studentIds: specificStudentsByContentType[genContentTypeKey(g.contentType, g.value)],
      }));

    const keys = await Promise.all(
      selectedFiles.map(async (file) => {
        const { uploadUrl, key } = await generateUploadUrl({
          jwtToken: session.token,
          payload: {
            key: `${file.uuid}/${file.file.name}`,
            type: file.file.type,
          },
        });

        await uploadFile({
          file: file.file,
          url: uploadUrl,
          onProgress: (progress) => {
            setSelectedFiles((prev) =>
              prev.map((f) => (f.uuid === file.uuid ? { ...f, progress, isLoading: true } : f)),
            );
          },
        });

        return key as string;
      }),
    );

    setSelectedFiles((prev) => prev.map((p) => ({ ...p, done: true })));

    await sendNotification({
      payload: {
        title,
        description,
        tutors: groups.parents,
        students: groups.students,
        teachers: groups.teachers,
        staff: groups.staff,
        fileKeys: keys,
        specificStudentListForSectionsAndOfficialSections,
      },
      jwtToken: session.token,
    });

    shoutSuccess('Notification sent successfully');
    setIsSending(false);
    clearState();
  };

  const ShowStudentList = (group: Groups) => {
    const key = genContentTypeKey(group.contentType, group.value);
    const handleClick = () => setShowStudentListByContentType((prev) => ({ ...prev, [key]: true }));

    return (
      <span className={styles['show-student-list-action']} onClick={handleClick}>
        <Trans i18nKey="SidePanel.editStudentList" />
      </span>
    );
  };

  const LocalStudentList = memo(
    ({
      label,
      id,
      internalType,
      contentType,
    }: {
      label: string;
      id: number;
      internalType: string;
      contentType: number;
    }) => {
      if (!['official-section', 'section'].includes(internalType)) return null;

      const fetchOfficialSectionStudents = useCallback(async () => {
        if (!session?.token) {
          return [];
        }

        return await getOfficialSectionStudents({
          jwtToken: session.token,
          id,
        });
      }, [id, session]);
      const fetchSectionStudents = useCallback(async () => {
        if (!session?.token) {
          return [];
        }

        return await getSectionStudents({
          jwtToken: session.token,
          id,
        });
      }, [id, session]);
      const call =
        internalType === 'official-section' ? fetchOfficialSectionStudents : fetchSectionStudents;
      const key = genContentTypeKey(contentType, id);

      useEffect(() => {
        if (key in studentsByContentType) return;

        call().then((students) => {
          setStudentsByContentType((prev) => ({ ...prev, [key]: students }));
          setSpecificStudentsByContentType((prev) => ({
            ...prev,
            [key]: students.map((s) => s.id),
          }));
        });
      }, []);

      if (!(key in studentsByContentType)) {
        return (
          <div className={styles['center-content']}>
            <Loader tint="gray" />
          </div>
        );
      }

      return (
        <StudentList
          groupName={label}
          // onSelectionChange={(ids) => setSpecificStudentsByContentType((prev) => ({ ...prev, [key]: ids }))}
          onStudentCheck={(id, checked) => {
            setSpecificStudentsByContentType((prev) => {
              const prevIds = prev[key] || [];
              const newIds = checked ? [...prevIds, id] : prevIds.filter((i) => i !== id);
              return { ...prev, [key]: newIds };
            });
          }}
          onSelecAll={() =>
            setSpecificStudentsByContentType((prev) => ({
              ...prev,
              [key]: (studentsByContentType[key] || []).map((s) => s.id),
            }))
          }
          onUnselectAll={() => setSpecificStudentsByContentType((prev) => ({ ...prev, [key]: [] }))}
          students={studentsByContentType[key] || []}
          selectedStudentIds={specificStudentsByContentType[key] || []}
        />
      );
    },
  );
  LocalStudentList.displayName = 'StudentList';

  return (
    <form
      className={`form notification-form ${styles['notification-form']}`}
      encType="multipart/form-data"
      acceptCharset="UTF-8"
    >
      <div className="grid">
        <div className="row">
          <div className="col xs-12">
            <label htmlFor="notificationTitle">
              <Trans i18nKey="SidePanel.notificationTitle" />:
            </label>
            <div className="input-wrapper input-small">
              <input
                id="notificationTitle"
                type="text"
                name="title"
                maxLength={800}
                placeholder={translation('SidePanel.notificationTitle')}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
          </div>

          <div className="col xs-12">
            <div className="input-wrapper input-small area">
              <textarea
                name="description"
                cols={40}
                rows={10}
                placeholder={translation('SidePanel.notificationDescription')}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <div className={styles['input-wrapper-container']}>
                <div className={styles['tools']}>
                  <input
                    multiple
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    type="file"
                  />
                  <button
                    type="button"
                    className="button button-small button-gray only-icon clear-background file-upload-button"
                    onClick={handleFileInputClick}
                  >
                    <i className="far fa-paperclip"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="activity-files-container">
        {selectedFiles.map(({ uuid, file, isLoading, progress, done }) => (
          <SelectedFile
            key={uuid}
            uuid={uuid}
            file={file}
            handleRemoveFile={handleRemoveFile}
            loading={isLoading}
            progress={progress}
            twinkle={done}
          />
        ))}
      </div>

      <label>
        <Trans i18nKey="SidePanel.notificationTitleGroup" />:
      </label>

      <div className="asignations-container with-assignations">
        {groupsToSendNotifications.map((group, index) => (
          <Group
            key={`group-${group.value}-${index}`}
            title={group.label}
            subtitle={group.subLabel}
            showGroupTypes={genShowGroupTypesConfiguration(group.groupType)}
            defaultValues={group.selectedGroupTypes}
            onRemove={() => {
              const key = genContentTypeKey(group.contentType, group.value);
              setGroupsToSendNotifications((prev) => prev.filter((_, i) => i !== index));
              setSpecificStudentsByContentType((prev) => {
                delete prev[key];
                return prev;
              });
              setShowStudentListByContentType((prev) => {
                delete prev[key];
                return prev;
              });
            }}
            onChange={(selectedGroup) => {
              if (!selectedGroup.parents && !selectedGroup.students) {
                const key = genContentTypeKey(group.contentType, group.value);
                setSpecificStudentsByContentType((prev) => {
                  delete prev[key];
                  return prev;
                });
                setStudentsByContentType((prev) => {
                  delete prev[key];
                  return prev;
                });
              }

              setGroupsToSendNotifications((prev) =>
                prev.map((e, i) => {
                  if (i !== index) return e;
                  return {
                    ...e,
                    selectedGroupTypes: selectedGroup,
                  };
                }),
              );
            }}
          >
            {['official-section', 'section'].includes(group.internalType || '') &&
              (group.selectedGroupTypes.parents || group.selectedGroupTypes.students) &&
              (showStudentListByContentType[genContentTypeKey(group.contentType, group.value)] ? (
                <LocalStudentList
                  label={group.label}
                  id={group.value}
                  internalType={group.internalType || ''}
                  contentType={group.contentType}
                />
              ) : (
                <ShowStudentList {...group} />
              ))}
          </Group>
        ))}
      </div>

      <div className="invite-widget">
        <div className="filter-container">
          <label className={`assignation-title popover-filter-search ${styles['search-filter']}`}>
            <span>
              <Trans i18nKey="SidePanel.searchBy" />:
            </span>
            <Select
              className={styles['search-select']}
              options={searchOptions}
              size="medium"
              value={selectedSearchType}
              onChange={setSelectedSearchType}
            />
          </label>
        </div>
      </div>

      <div className={styles['search-container']}>
        <SearchTypeahead
          searchType={selectedSearchType.value}
          onSearchResult={(result) => {
            if (
              groupsToSendNotifications.find(
                (e) => e.contentType === result.contentType && e.value === result.value,
              )
            )
              return;

            console.log('Result:', result);
            setGroupsToSendNotifications((prev) => [
              ...prev,
              {
                ...result,
                selectedGroupTypes: {
                  staff: result.groupType === 'staff',
                  students: ['structure', 'school', 'person'].includes(result.groupType),
                  // TODO: check the result group type to activate the 'student' (singular) option
                  // currently, there's no way to know if the result is a single student or a tutor
                },
              },
            ]);
          }}
        />
      </div>

      <div className="center-text">
        <button
          type="button"
          className={`button button-success ${!formIsReadyToSend || isSending ? 'disabled' : ''}`}
          disabled={!formIsReadyToSend || isSending}
          onClick={handleOnSubmit}
        >
          <i className="far fa-bell"></i>
          <span className="text">
            <Trans i18nKey="SidePanel.submit" />
          </span>
        </button>
      </div>
    </form>
  );
};
