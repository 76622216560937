import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Modal } from '@nahualventure/paper-ui';

import styles from './requestUpdateModal.module.scss';

type RequestUpdateDataModalPropsType = {
  open: boolean;
  onClose?: (event: React.MouseEvent<HTMLElement>) => void;
  onAccept?: (event: React.MouseEvent<HTMLElement>) => void;
};

const RequestUpdateDataModal: React.FunctionComponent<RequestUpdateDataModalPropsType> = ({
  open,
  onClose = () => {},
  onAccept,
}: RequestUpdateDataModalPropsType) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <Modal onClose={onClose as () => void} open={open} size="normal">
      <div className={styles.modal}>
        <div className={`${styles.closeButton} popup popup-alert`}>
          <button title="Close (Esc)" className="mfp-close" onClick={onClose} />
        </div>
        <div className={styles.body}>
          <div className={styles.title}>
            <Trans i18nKey="SchoolConfig.requestDataUpdateModalTitle">
              Request tutors to <span className={styles.accent}>update their data</span>?
            </Trans>
          </div>
          <div className={styles.content}>
            <Trans i18nKey="SchoolConfig.requestDataUpdateModalContent">
              This action will show a series of forms to the tutors when they login to Edoo, from
              where they can{' '}
              <strong>validate or update personal information of them and their students.</strong>
            </Trans>
          </div>
          <div className={styles.actions}>
            <Button color="gray" size="large" className={styles.button} onClick={onClose}>
              {t('SchoolConfig.modalDecline')}
            </Button>
            <Button
              size="large"
              className={styles.button}
              onClick={() => {
                setIsLoading(true);
                setTimeout((e) => {
                  onAccept && onAccept(e);
                  setIsLoading(false);
                }, 2000);
              }}
              isLoading={isLoading}
            >
              {t('SchoolConfig.modalAccept')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RequestUpdateDataModal;
