export type DjangoUrlsType = {
  urls: [string, [string, string[]][]][];
  prefix: string;
};

export const DJANGO_URLS: DjangoUrlsType = {
  urls: [
    ['account_install', [['account/install/', []]]],
    [
      'activate_student',
      [['profile/student/%(student_profile_pk)s/activate-student/', ['student_profile_pk']]],
    ],
    ['activate_user', [['user/student/%(user_pk)s/activate-user/', ['user_pk']]]],
    [
      'activities-detail',
      [
        ['public-api/v1/activities/%(pk)s.%(format)s', ['pk', 'format']],
        ['public-api/v1/activities/%(pk)s/', ['pk']],
      ],
    ],
    [
      'activities-full-upgrade',
      [
        ['public-api/v1/activities/full-upgrade.%(format)s', ['format']],
        ['public-api/v1/activities/full-upgrade/', []],
      ],
    ],
    [
      'activities-set-scores-in-bulk',
      [
        ['public-api/v1/activities/set-scores-in-bulk.%(format)s', ['format']],
        ['public-api/v1/activities/set-scores-in-bulk/', []],
      ],
    ],
    [
      'activities_activity_add_checklists',
      [['activities/activity/%(activity_pk)s/add-checklists/', ['activity_pk']]],
    ],
    [
      'activities_activity_add_rubric',
      [
        [
          'activities/activity/%(activity_pk)s/add-rubric/%(rubric_pk)s/',
          ['activity_pk', 'rubric_pk'],
        ],
      ],
    ],
    [
      'activities_activity_remove_checklist',
      [
        [
          'activities/activity/%(activity_pk)s/remove-checklist/%(checklist_pk)s/',
          ['activity_pk', 'checklist_pk'],
        ],
      ],
    ],
    [
      'activities_activity_remove_rubric',
      [['activities/activity/%(activity_pk)s/remove-rubric/', ['activity_pk']]],
    ],
    [
      'activities_activity_score_achievement',
      [['activities/activity/%(activity_pk)s/score-achievement/', ['activity_pk']]],
    ],
    [
      'activities_activity_score_checklist',
      [['activities/activity/%(activity_pk)s/score-checklist/', ['activity_pk']]],
    ],
    [
      'activities_activity_score_rubric',
      [['activities/activity/%(activity_pk)s/score-rubric/', ['activity_pk']]],
    ],
    [
      'activities_add_extra_credit',
      [['activities/activity/%(activity_pk)s/add-extra-credit/', ['activity_pk']]],
    ],
    ['activities_add_resource', [['activities/resource/add/', []]]],
    [
      'activities_add_score_substitution',
      [['activities/activity/%(activity_pk)s/add-score-substitution/', ['activity_pk']]],
    ],
    [
      'activities_change_visibility',
      [['activities/activity/%(activity_pk)s/change-visibility/', ['activity_pk']]],
    ],
    ['activities_detail', [['api/activities/%(pk)s/', ['pk']]]],
    ['activities_event_add', [['activities/event/add/', []]]],
    ['activities_event_delete', [['activities/event/%(event_pk)s/delete/', ['event_pk']]]],
    ['activities_exam_add', [['activities/exam/add/', []]]],
    ['activities_exam_delete', [['activities/exam/%(exam_pk)s/delete/', ['exam_pk']]]],
    ['activities_exam_edit', [['activities/exam/%(exam_pk)s/edit/', ['exam_pk']]]],
    ['activities_homework_add', [['activities/homework/add/', []]]],
    [
      'activities_homework_delete',
      [['activities/homework/%(homework_pk)s/delete/', ['homework_pk']]],
    ],
    ['activities_homework_edit', [['activities/homework/%(homework_pk)s/edit/', ['homework_pk']]]],
    ['activities_list', [['api/activities/', []]]],
    [
      'activities_remove_extra_credit',
      [['activities/activity/%(activity_pk)s/remove-extra-credit/', ['activity_pk']]],
    ],
    [
      'activities_remove_resource',
      [['activities/resource/%(resource_pk)s/remove/', ['resource_pk']]],
    ],
    [
      'activities_remove_score_substitution',
      [['activities/activity/%(activity_pk)s/remove-score-substitution/', ['activity_pk']]],
    ],
    ['activity_content', [['activities/activity-content/', []]]],
    ['activity_log_items', [['dashboard/teacher/activity-log', []]]],
    [
      'add_comment_score_register',
      [['activities/activity/%(activity_pk)s/add-comment-score-register/', ['activity_pk']]],
    ],
    ['add_course_supervisor', [['cycle/course/%(course_pk)s/supervisors/add/', ['course_pk']]]],
    ['add_cycle', [['cycle/add-cycle/', []]]],
    ['add_extra_curricular', [['cycle/extra-curricular/add/', []]]],
    [
      'add_extra_curricular_level',
      [['cycle/extra-curricular/add/level/%(level_pk)s/', ['level_pk']]],
    ],
    [
      'add_extra_curricular_stage',
      [['cycle/extra-curricular/add/stage/%(stage_pk)s/', ['stage_pk']]],
    ],
    [
      'add_extra_curricular_supervisor',
      [
        [
          'cycle/extra-curricular/%(extra_curricular_pk)s/supervisors/add/',
          ['extra_curricular_pk'],
        ],
      ],
    ],
    ['add_gradecell', [['gradecell/%(gradecell_pk)s/add/', ['gradecell_pk']]]],
    ['add_level_supervisor', [['structure/level/%(level_id)s/supervisors/add/', ['level_id']]]],
    [
      'add_levelgroup_supervisor',
      [['levelgroup/add-supervisor/%(levelgroup_pk)s/', ['levelgroup_pk']]],
    ],
    ['add_model_unit', [['model-unit/%(gradecell_pk)s/add/', ['gradecell_pk']]]],
    [
      'add_official_section',
      [['structure/level/%(level_pk)s/add-official-section/', ['level_pk']]],
    ],
    [
      'add_official_section_schedule',
      [['cycle/add-schedule/%(official_section_pk)s/', ['official_section_pk']]],
    ],
    [
      'add_section_announcement',
      [['cycle/section/%(section_pk)s/announcements/add', ['section_pk']]],
    ],
    [
      'add_section_announcement_comment',
      [
        [
          'cycle/section/%(section_pk)s/annoucements/%(announcement_pk)s/add-comment',
          ['section_pk', 'announcement_pk'],
        ],
      ],
    ],
    [
      'add_section_supervisor',
      [['cycle/section/edit/%(section_pk)s/supervisors/add', ['section_pk']]],
    ],
    ['add_stage', [['structure/add-stage/', []]]],
    ['add_stage_supervisor', [['structure/stage/%(stage_pk)s/supervisors/add/', ['stage_pk']]]],
    ['add_student_contact', [['profile/student/%(username)s/add-student-contact/', ['username']]]],
    ['add_subcourse', [['api/cycle/cycles/%(course_pk)s/subcourses/add/', ['course_pk']]]],
    [
      'add_to_current_cycle',
      [['profile/student/%(student_profile_pk)s/add-to-current-cycle/', ['student_profile_pk']]],
    ],
    ['add_unit', [['unit/%(gradecell_pk)s/add/', ['gradecell_pk']]]],
    ['admin:achievements_achievement_add', [['wp-admin/achievements/achievement/add/', []]]],
    [
      'admin:achievements_achievement_change',
      [['wp-admin/achievements/achievement/%(object_id)s/change/', ['object_id']]],
    ],
    ['admin:achievements_achievement_changelist', [['wp-admin/achievements/achievement/', []]]],
    [
      'admin:achievements_achievement_delete',
      [['wp-admin/achievements/achievement/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:achievements_achievement_history',
      [['wp-admin/achievements/achievement/%(object_id)s/history/', ['object_id']]],
    ],
    [
      'admin:achievements_customuserachievementassignation_add',
      [['wp-admin/achievements/customuserachievementassignation/add/', []]],
    ],
    [
      'admin:achievements_customuserachievementassignation_change',
      [
        [
          'wp-admin/achievements/customuserachievementassignation/%(object_id)s/change/',
          ['object_id'],
        ],
      ],
    ],
    [
      'admin:achievements_customuserachievementassignation_changelist',
      [['wp-admin/achievements/customuserachievementassignation/', []]],
    ],
    [
      'admin:achievements_customuserachievementassignation_delete',
      [
        [
          'wp-admin/achievements/customuserachievementassignation/%(object_id)s/delete/',
          ['object_id'],
        ],
      ],
    ],
    [
      'admin:achievements_customuserachievementassignation_history',
      [
        [
          'wp-admin/achievements/customuserachievementassignation/%(object_id)s/history/',
          ['object_id'],
        ],
      ],
    ],
    [
      'admin:achievements_scoreregisterachievementassignation_add',
      [['wp-admin/achievements/scoreregisterachievementassignation/add/', []]],
    ],
    [
      'admin:achievements_scoreregisterachievementassignation_change',
      [
        [
          'wp-admin/achievements/scoreregisterachievementassignation/%(object_id)s/change/',
          ['object_id'],
        ],
      ],
    ],
    [
      'admin:achievements_scoreregisterachievementassignation_changelist',
      [['wp-admin/achievements/scoreregisterachievementassignation/', []]],
    ],
    [
      'admin:achievements_scoreregisterachievementassignation_delete',
      [
        [
          'wp-admin/achievements/scoreregisterachievementassignation/%(object_id)s/delete/',
          ['object_id'],
        ],
      ],
    ],
    [
      'admin:achievements_scoreregisterachievementassignation_history',
      [
        [
          'wp-admin/achievements/scoreregisterachievementassignation/%(object_id)s/history/',
          ['object_id'],
        ],
      ],
    ],
    ['admin:activities_event_add', [['wp-admin/activities/event/add/', []]]],
    [
      'admin:activities_event_change',
      [['wp-admin/activities/event/%(object_id)s/change/', ['object_id']]],
    ],
    ['admin:activities_event_changelist', [['wp-admin/activities/event/', []]]],
    [
      'admin:activities_event_delete',
      [['wp-admin/activities/event/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:activities_event_history',
      [['wp-admin/activities/event/%(object_id)s/history/', ['object_id']]],
    ],
    ['admin:activities_structureevent_add', [['wp-admin/activities/structureevent/add/', []]]],
    [
      'admin:activities_structureevent_change',
      [['wp-admin/activities/structureevent/%(object_id)s/change/', ['object_id']]],
    ],
    ['admin:activities_structureevent_changelist', [['wp-admin/activities/structureevent/', []]]],
    [
      'admin:activities_structureevent_delete',
      [['wp-admin/activities/structureevent/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:activities_structureevent_history',
      [['wp-admin/activities/structureevent/%(object_id)s/history/', ['object_id']]],
    ],
    ['admin:app_list', [['wp-admin/%(app_label)s/', ['app_label']]]],
    ['admin:auth_group_add', [['wp-admin/auth/group/add/', []]]],
    ['admin:auth_group_change', [['wp-admin/auth/group/%(object_id)s/change/', ['object_id']]]],
    ['admin:auth_group_changelist', [['wp-admin/auth/group/', []]]],
    ['admin:auth_group_delete', [['wp-admin/auth/group/%(object_id)s/delete/', ['object_id']]]],
    ['admin:auth_group_history', [['wp-admin/auth/group/%(object_id)s/history/', ['object_id']]]],
    ['admin:authtoken_tokenproxy_add', [['wp-admin/authtoken/tokenproxy/add/', []]]],
    [
      'admin:authtoken_tokenproxy_change',
      [['wp-admin/authtoken/tokenproxy/%(object_id)s/change/', ['object_id']]],
    ],
    ['admin:authtoken_tokenproxy_changelist', [['wp-admin/authtoken/tokenproxy/', []]]],
    [
      'admin:authtoken_tokenproxy_delete',
      [['wp-admin/authtoken/tokenproxy/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:authtoken_tokenproxy_history',
      [['wp-admin/authtoken/tokenproxy/%(object_id)s/history/', ['object_id']]],
    ],
    ['admin:autocomplete', [['wp-admin/autocomplete/', []]]],
    [
      'admin:configurations_configurationtype_add',
      [['wp-admin/configurations/configurationtype/add/', []]],
    ],
    [
      'admin:configurations_configurationtype_change',
      [['wp-admin/configurations/configurationtype/%(object_id)s/change/', ['object_id']]],
    ],
    [
      'admin:configurations_configurationtype_changelist',
      [['wp-admin/configurations/configurationtype/', []]],
    ],
    [
      'admin:configurations_configurationtype_delete',
      [['wp-admin/configurations/configurationtype/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:configurations_configurationtype_history',
      [['wp-admin/configurations/configurationtype/%(object_id)s/history/', ['object_id']]],
    ],
    [
      'admin:configurations_genericconfiguration_add',
      [['wp-admin/configurations/genericconfiguration/add/', []]],
    ],
    [
      'admin:configurations_genericconfiguration_change',
      [['wp-admin/configurations/genericconfiguration/%(object_id)s/change/', ['object_id']]],
    ],
    [
      'admin:configurations_genericconfiguration_changelist',
      [['wp-admin/configurations/genericconfiguration/', []]],
    ],
    [
      'admin:configurations_genericconfiguration_delete',
      [['wp-admin/configurations/genericconfiguration/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:configurations_genericconfiguration_history',
      [['wp-admin/configurations/genericconfiguration/%(object_id)s/history/', ['object_id']]],
    ],
    [
      'admin:configurations_systemconfiguration_add',
      [['wp-admin/configurations/systemconfiguration/add/', []]],
    ],
    [
      'admin:configurations_systemconfiguration_change',
      [['wp-admin/configurations/systemconfiguration/%(object_id)s/change/', ['object_id']]],
    ],
    [
      'admin:configurations_systemconfiguration_changelist',
      [['wp-admin/configurations/systemconfiguration/', []]],
    ],
    [
      'admin:configurations_systemconfiguration_delete',
      [['wp-admin/configurations/systemconfiguration/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:configurations_systemconfiguration_history',
      [['wp-admin/configurations/systemconfiguration/%(object_id)s/history/', ['object_id']]],
    ],
    [
      'admin:configurations_userconfiguration_add',
      [['wp-admin/configurations/userconfiguration/add/', []]],
    ],
    [
      'admin:configurations_userconfiguration_change',
      [['wp-admin/configurations/userconfiguration/%(object_id)s/change/', ['object_id']]],
    ],
    [
      'admin:configurations_userconfiguration_changelist',
      [['wp-admin/configurations/userconfiguration/', []]],
    ],
    [
      'admin:configurations_userconfiguration_delete',
      [['wp-admin/configurations/userconfiguration/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:configurations_userconfiguration_history',
      [['wp-admin/configurations/userconfiguration/%(object_id)s/history/', ['object_id']]],
    ],
    ['admin:cycle_course_add', [['wp-admin/cycle/course/add/', []]]],
    ['admin:cycle_course_change', [['wp-admin/cycle/course/%(object_id)s/change/', ['object_id']]]],
    ['admin:cycle_course_changelist', [['wp-admin/cycle/course/', []]]],
    ['admin:cycle_course_delete', [['wp-admin/cycle/course/%(object_id)s/delete/', ['object_id']]]],
    [
      'admin:cycle_course_history',
      [['wp-admin/cycle/course/%(object_id)s/history/', ['object_id']]],
    ],
    ['admin:cycle_cycle_add', [['wp-admin/cycle/cycle/add/', []]]],
    ['admin:cycle_cycle_change', [['wp-admin/cycle/cycle/%(object_id)s/change/', ['object_id']]]],
    ['admin:cycle_cycle_changelist', [['wp-admin/cycle/cycle/', []]]],
    ['admin:cycle_cycle_delete', [['wp-admin/cycle/cycle/%(object_id)s/delete/', ['object_id']]]],
    ['admin:cycle_cycle_history', [['wp-admin/cycle/cycle/%(object_id)s/history/', ['object_id']]]],
    [
      'admin:discipline_customuserdisciplinefaultassignation_add',
      [['wp-admin/discipline/customuserdisciplinefaultassignation/add/', []]],
    ],
    [
      'admin:discipline_customuserdisciplinefaultassignation_change',
      [
        [
          'wp-admin/discipline/customuserdisciplinefaultassignation/%(object_id)s/change/',
          ['object_id'],
        ],
      ],
    ],
    [
      'admin:discipline_customuserdisciplinefaultassignation_changelist',
      [['wp-admin/discipline/customuserdisciplinefaultassignation/', []]],
    ],
    [
      'admin:discipline_customuserdisciplinefaultassignation_delete',
      [
        [
          'wp-admin/discipline/customuserdisciplinefaultassignation/%(object_id)s/delete/',
          ['object_id'],
        ],
      ],
    ],
    [
      'admin:discipline_customuserdisciplinefaultassignation_history',
      [
        [
          'wp-admin/discipline/customuserdisciplinefaultassignation/%(object_id)s/history/',
          ['object_id'],
        ],
      ],
    ],
    ['admin:discipline_disciplinefault_add', [['wp-admin/discipline/disciplinefault/add/', []]]],
    [
      'admin:discipline_disciplinefault_change',
      [['wp-admin/discipline/disciplinefault/%(object_id)s/change/', ['object_id']]],
    ],
    ['admin:discipline_disciplinefault_changelist', [['wp-admin/discipline/disciplinefault/', []]]],
    [
      'admin:discipline_disciplinefault_delete',
      [['wp-admin/discipline/disciplinefault/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:discipline_disciplinefault_history',
      [['wp-admin/discipline/disciplinefault/%(object_id)s/history/', ['object_id']]],
    ],
    [
      'admin:discipline_disciplinefaultcategory_add',
      [['wp-admin/discipline/disciplinefaultcategory/add/', []]],
    ],
    [
      'admin:discipline_disciplinefaultcategory_change',
      [['wp-admin/discipline/disciplinefaultcategory/%(object_id)s/change/', ['object_id']]],
    ],
    [
      'admin:discipline_disciplinefaultcategory_changelist',
      [['wp-admin/discipline/disciplinefaultcategory/', []]],
    ],
    [
      'admin:discipline_disciplinefaultcategory_delete',
      [['wp-admin/discipline/disciplinefaultcategory/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:discipline_disciplinefaultcategory_history',
      [['wp-admin/discipline/disciplinefaultcategory/%(object_id)s/history/', ['object_id']]],
    ],
    ['admin:django_comments_comment_add', [['wp-admin/django_comments/comment/add/', []]]],
    [
      'admin:django_comments_comment_change',
      [['wp-admin/django_comments/comment/%(object_id)s/change/', ['object_id']]],
    ],
    ['admin:django_comments_comment_changelist', [['wp-admin/django_comments/comment/', []]]],
    [
      'admin:django_comments_comment_delete',
      [['wp-admin/django_comments/comment/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:django_comments_comment_history',
      [['wp-admin/django_comments/comment/%(object_id)s/history/', ['object_id']]],
    ],
    ['admin:dlink_dlinkcam_add', [['wp-admin/dlink/dlinkcam/add/', []]]],
    [
      'admin:dlink_dlinkcam_change',
      [['wp-admin/dlink/dlinkcam/%(object_id)s/change/', ['object_id']]],
    ],
    ['admin:dlink_dlinkcam_changelist', [['wp-admin/dlink/dlinkcam/', []]]],
    [
      'admin:dlink_dlinkcam_delete',
      [['wp-admin/dlink/dlinkcam/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:dlink_dlinkcam_history',
      [['wp-admin/dlink/dlinkcam/%(object_id)s/history/', ['object_id']]],
    ],
    ['admin:fcm_django_fcmdevice_add', [['wp-admin/fcm_django/fcmdevice/add/', []]]],
    [
      'admin:fcm_django_fcmdevice_change',
      [['wp-admin/fcm_django/fcmdevice/%(object_id)s/change/', ['object_id']]],
    ],
    ['admin:fcm_django_fcmdevice_changelist', [['wp-admin/fcm_django/fcmdevice/', []]]],
    [
      'admin:fcm_django_fcmdevice_delete',
      [['wp-admin/fcm_django/fcmdevice/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:fcm_django_fcmdevice_history',
      [['wp-admin/fcm_django/fcmdevice/%(object_id)s/history/', ['object_id']]],
    ],
    ['admin:files_categoryfolder_add', [['wp-admin/files/categoryfolder/add/', []]]],
    [
      'admin:files_categoryfolder_change',
      [['wp-admin/files/categoryfolder/%(object_id)s/change/', ['object_id']]],
    ],
    ['admin:files_categoryfolder_changelist', [['wp-admin/files/categoryfolder/', []]]],
    [
      'admin:files_categoryfolder_delete',
      [['wp-admin/files/categoryfolder/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:files_categoryfolder_history',
      [['wp-admin/files/categoryfolder/%(object_id)s/history/', ['object_id']]],
    ],
    ['admin:files_directaccess_add', [['wp-admin/files/directaccess/add/', []]]],
    [
      'admin:files_directaccess_change',
      [['wp-admin/files/directaccess/%(object_id)s/change/', ['object_id']]],
    ],
    ['admin:files_directaccess_changelist', [['wp-admin/files/directaccess/', []]]],
    [
      'admin:files_directaccess_delete',
      [['wp-admin/files/directaccess/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:files_directaccess_history',
      [['wp-admin/files/directaccess/%(object_id)s/history/', ['object_id']]],
    ],
    ['admin:files_resourcefile_add', [['wp-admin/files/resourcefile/add/', []]]],
    [
      'admin:files_resourcefile_change',
      [['wp-admin/files/resourcefile/%(object_id)s/change/', ['object_id']]],
    ],
    ['admin:files_resourcefile_changelist', [['wp-admin/files/resourcefile/', []]]],
    [
      'admin:files_resourcefile_delete',
      [['wp-admin/files/resourcefile/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:files_resourcefile_history',
      [['wp-admin/files/resourcefile/%(object_id)s/history/', ['object_id']]],
    ],
    ['admin:gates_gate_add', [['wp-admin/gates/gate/add/', []]]],
    ['admin:gates_gate_change', [['wp-admin/gates/gate/%(object_id)s/change/', ['object_id']]]],
    ['admin:gates_gate_changelist', [['wp-admin/gates/gate/', []]]],
    ['admin:gates_gate_delete', [['wp-admin/gates/gate/%(object_id)s/delete/', ['object_id']]]],
    ['admin:gates_gate_history', [['wp-admin/gates/gate/%(object_id)s/history/', ['object_id']]]],
    ['admin:gates_gateexit_add', [['wp-admin/gates/gateexit/add/', []]]],
    [
      'admin:gates_gateexit_change',
      [['wp-admin/gates/gateexit/%(object_id)s/change/', ['object_id']]],
    ],
    ['admin:gates_gateexit_changelist', [['wp-admin/gates/gateexit/', []]]],
    [
      'admin:gates_gateexit_delete',
      [['wp-admin/gates/gateexit/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:gates_gateexit_history',
      [['wp-admin/gates/gateexit/%(object_id)s/history/', ['object_id']]],
    ],
    ['admin:gates_studentgate_add', [['wp-admin/gates/studentgate/add/', []]]],
    [
      'admin:gates_studentgate_change',
      [['wp-admin/gates/studentgate/%(object_id)s/change/', ['object_id']]],
    ],
    ['admin:gates_studentgate_changelist', [['wp-admin/gates/studentgate/', []]]],
    [
      'admin:gates_studentgate_delete',
      [['wp-admin/gates/studentgate/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:gates_studentgate_history',
      [['wp-admin/gates/studentgate/%(object_id)s/history/', ['object_id']]],
    ],
    ['admin:index', [['wp-admin/', []]]],
    ['admin:integrations_integration_add', [['wp-admin/integrations/integration/add/', []]]],
    [
      'admin:integrations_integration_change',
      [['wp-admin/integrations/integration/%(object_id)s/change/', ['object_id']]],
    ],
    ['admin:integrations_integration_changelist', [['wp-admin/integrations/integration/', []]]],
    [
      'admin:integrations_integration_delete',
      [['wp-admin/integrations/integration/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:integrations_integration_history',
      [['wp-admin/integrations/integration/%(object_id)s/history/', ['object_id']]],
    ],
    [
      'admin:integrations_integrationconfig_add',
      [['wp-admin/integrations/integrationconfig/add/', []]],
    ],
    [
      'admin:integrations_integrationconfig_change',
      [['wp-admin/integrations/integrationconfig/%(object_id)s/change/', ['object_id']]],
    ],
    [
      'admin:integrations_integrationconfig_changelist',
      [['wp-admin/integrations/integrationconfig/', []]],
    ],
    [
      'admin:integrations_integrationconfig_delete',
      [['wp-admin/integrations/integrationconfig/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:integrations_integrationconfig_history',
      [['wp-admin/integrations/integrationconfig/%(object_id)s/history/', ['object_id']]],
    ],
    ['admin:jsi18n', [['wp-admin/jsi18n/', []]]],
    ['admin:login', [['wp-admin/login/', []]]],
    ['admin:logout', [['wp-admin/logout/', []]]],
    ['admin:oauth2_provider_accesstoken_add', [['wp-admin/oauth2_provider/accesstoken/add/', []]]],
    [
      'admin:oauth2_provider_accesstoken_change',
      [['wp-admin/oauth2_provider/accesstoken/%(object_id)s/change/', ['object_id']]],
    ],
    [
      'admin:oauth2_provider_accesstoken_changelist',
      [['wp-admin/oauth2_provider/accesstoken/', []]],
    ],
    [
      'admin:oauth2_provider_accesstoken_delete',
      [['wp-admin/oauth2_provider/accesstoken/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:oauth2_provider_accesstoken_history',
      [['wp-admin/oauth2_provider/accesstoken/%(object_id)s/history/', ['object_id']]],
    ],
    ['admin:oauth2_provider_application_add', [['wp-admin/oauth2_provider/application/add/', []]]],
    [
      'admin:oauth2_provider_application_change',
      [['wp-admin/oauth2_provider/application/%(object_id)s/change/', ['object_id']]],
    ],
    [
      'admin:oauth2_provider_application_changelist',
      [['wp-admin/oauth2_provider/application/', []]],
    ],
    [
      'admin:oauth2_provider_application_delete',
      [['wp-admin/oauth2_provider/application/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:oauth2_provider_application_history',
      [['wp-admin/oauth2_provider/application/%(object_id)s/history/', ['object_id']]],
    ],
    ['admin:oauth2_provider_grant_add', [['wp-admin/oauth2_provider/grant/add/', []]]],
    [
      'admin:oauth2_provider_grant_change',
      [['wp-admin/oauth2_provider/grant/%(object_id)s/change/', ['object_id']]],
    ],
    ['admin:oauth2_provider_grant_changelist', [['wp-admin/oauth2_provider/grant/', []]]],
    [
      'admin:oauth2_provider_grant_delete',
      [['wp-admin/oauth2_provider/grant/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:oauth2_provider_grant_history',
      [['wp-admin/oauth2_provider/grant/%(object_id)s/history/', ['object_id']]],
    ],
    ['admin:oauth2_provider_idtoken_add', [['wp-admin/oauth2_provider/idtoken/add/', []]]],
    [
      'admin:oauth2_provider_idtoken_change',
      [['wp-admin/oauth2_provider/idtoken/%(object_id)s/change/', ['object_id']]],
    ],
    ['admin:oauth2_provider_idtoken_changelist', [['wp-admin/oauth2_provider/idtoken/', []]]],
    [
      'admin:oauth2_provider_idtoken_delete',
      [['wp-admin/oauth2_provider/idtoken/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:oauth2_provider_idtoken_history',
      [['wp-admin/oauth2_provider/idtoken/%(object_id)s/history/', ['object_id']]],
    ],
    [
      'admin:oauth2_provider_refreshtoken_add',
      [['wp-admin/oauth2_provider/refreshtoken/add/', []]],
    ],
    [
      'admin:oauth2_provider_refreshtoken_change',
      [['wp-admin/oauth2_provider/refreshtoken/%(object_id)s/change/', ['object_id']]],
    ],
    [
      'admin:oauth2_provider_refreshtoken_changelist',
      [['wp-admin/oauth2_provider/refreshtoken/', []]],
    ],
    [
      'admin:oauth2_provider_refreshtoken_delete',
      [['wp-admin/oauth2_provider/refreshtoken/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:oauth2_provider_refreshtoken_history',
      [['wp-admin/oauth2_provider/refreshtoken/%(object_id)s/history/', ['object_id']]],
    ],
    ['admin:password_change', [['wp-admin/password_change/', []]]],
    ['admin:password_change_done', [['wp-admin/password_change/done/', []]]],
    [
      'admin:push_notifications_apnsdevice_add',
      [['wp-admin/push_notifications/apnsdevice/add/', []]],
    ],
    [
      'admin:push_notifications_apnsdevice_change',
      [['wp-admin/push_notifications/apnsdevice/%(object_id)s/change/', ['object_id']]],
    ],
    [
      'admin:push_notifications_apnsdevice_changelist',
      [['wp-admin/push_notifications/apnsdevice/', []]],
    ],
    [
      'admin:push_notifications_apnsdevice_delete',
      [['wp-admin/push_notifications/apnsdevice/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:push_notifications_apnsdevice_history',
      [['wp-admin/push_notifications/apnsdevice/%(object_id)s/history/', ['object_id']]],
    ],
    [
      'admin:push_notifications_gcmdevice_add',
      [['wp-admin/push_notifications/gcmdevice/add/', []]],
    ],
    [
      'admin:push_notifications_gcmdevice_change',
      [['wp-admin/push_notifications/gcmdevice/%(object_id)s/change/', ['object_id']]],
    ],
    [
      'admin:push_notifications_gcmdevice_changelist',
      [['wp-admin/push_notifications/gcmdevice/', []]],
    ],
    [
      'admin:push_notifications_gcmdevice_delete',
      [['wp-admin/push_notifications/gcmdevice/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:push_notifications_gcmdevice_history',
      [['wp-admin/push_notifications/gcmdevice/%(object_id)s/history/', ['object_id']]],
    ],
    [
      'admin:push_notifications_webpushdevice_add',
      [['wp-admin/push_notifications/webpushdevice/add/', []]],
    ],
    [
      'admin:push_notifications_webpushdevice_change',
      [['wp-admin/push_notifications/webpushdevice/%(object_id)s/change/', ['object_id']]],
    ],
    [
      'admin:push_notifications_webpushdevice_changelist',
      [['wp-admin/push_notifications/webpushdevice/', []]],
    ],
    [
      'admin:push_notifications_webpushdevice_delete',
      [['wp-admin/push_notifications/webpushdevice/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:push_notifications_webpushdevice_history',
      [['wp-admin/push_notifications/webpushdevice/%(object_id)s/history/', ['object_id']]],
    ],
    [
      'admin:push_notifications_wnsdevice_add',
      [['wp-admin/push_notifications/wnsdevice/add/', []]],
    ],
    [
      'admin:push_notifications_wnsdevice_change',
      [['wp-admin/push_notifications/wnsdevice/%(object_id)s/change/', ['object_id']]],
    ],
    [
      'admin:push_notifications_wnsdevice_changelist',
      [['wp-admin/push_notifications/wnsdevice/', []]],
    ],
    [
      'admin:push_notifications_wnsdevice_delete',
      [['wp-admin/push_notifications/wnsdevice/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:push_notifications_wnsdevice_history',
      [['wp-admin/push_notifications/wnsdevice/%(object_id)s/history/', ['object_id']]],
    ],
    ['admin:reports_customizationform_add', [['wp-admin/reports/customizationform/add/', []]]],
    [
      'admin:reports_customizationform_change',
      [['wp-admin/reports/customizationform/%(object_id)s/change/', ['object_id']]],
    ],
    ['admin:reports_customizationform_changelist', [['wp-admin/reports/customizationform/', []]]],
    [
      'admin:reports_customizationform_delete',
      [['wp-admin/reports/customizationform/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:reports_customizationform_history',
      [['wp-admin/reports/customizationform/%(object_id)s/history/', ['object_id']]],
    ],
    ['admin:reports_dataextractor_add', [['wp-admin/reports/dataextractor/add/', []]]],
    [
      'admin:reports_dataextractor_change',
      [['wp-admin/reports/dataextractor/%(object_id)s/change/', ['object_id']]],
    ],
    ['admin:reports_dataextractor_changelist', [['wp-admin/reports/dataextractor/', []]]],
    [
      'admin:reports_dataextractor_delete',
      [['wp-admin/reports/dataextractor/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:reports_dataextractor_history',
      [['wp-admin/reports/dataextractor/%(object_id)s/history/', ['object_id']]],
    ],
    ['admin:reports_datasourceextractor_add', [['wp-admin/reports/datasourceextractor/add/', []]]],
    [
      'admin:reports_datasourceextractor_change',
      [['wp-admin/reports/datasourceextractor/%(object_id)s/change/', ['object_id']]],
    ],
    [
      'admin:reports_datasourceextractor_changelist',
      [['wp-admin/reports/datasourceextractor/', []]],
    ],
    [
      'admin:reports_datasourceextractor_delete',
      [['wp-admin/reports/datasourceextractor/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:reports_datasourceextractor_history',
      [['wp-admin/reports/datasourceextractor/%(object_id)s/history/', ['object_id']]],
    ],
    ['admin:reports_entryform_add', [['wp-admin/reports/entryform/add/', []]]],
    [
      'admin:reports_entryform_change',
      [['wp-admin/reports/entryform/%(object_id)s/change/', ['object_id']]],
    ],
    ['admin:reports_entryform_changelist', [['wp-admin/reports/entryform/', []]]],
    [
      'admin:reports_entryform_delete',
      [['wp-admin/reports/entryform/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:reports_entryform_history',
      [['wp-admin/reports/entryform/%(object_id)s/history/', ['object_id']]],
    ],
    ['admin:reports_favoriteuserreports_add', [['wp-admin/reports/favoriteuserreports/add/', []]]],
    [
      'admin:reports_favoriteuserreports_change',
      [['wp-admin/reports/favoriteuserreports/%(object_id)s/change/', ['object_id']]],
    ],
    [
      'admin:reports_favoriteuserreports_changelist',
      [['wp-admin/reports/favoriteuserreports/', []]],
    ],
    [
      'admin:reports_favoriteuserreports_delete',
      [['wp-admin/reports/favoriteuserreports/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:reports_favoriteuserreports_history',
      [['wp-admin/reports/favoriteuserreports/%(object_id)s/history/', ['object_id']]],
    ],
    ['admin:reports_report_add', [['wp-admin/reports/report/add/', []]]],
    [
      'admin:reports_report_change',
      [['wp-admin/reports/report/%(object_id)s/change/', ['object_id']]],
    ],
    ['admin:reports_report_changelist', [['wp-admin/reports/report/', []]]],
    [
      'admin:reports_report_delete',
      [['wp-admin/reports/report/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:reports_report_history',
      [['wp-admin/reports/report/%(object_id)s/history/', ['object_id']]],
    ],
    ['admin:reports_reportcategory_add', [['wp-admin/reports/reportcategory/add/', []]]],
    [
      'admin:reports_reportcategory_change',
      [['wp-admin/reports/reportcategory/%(object_id)s/change/', ['object_id']]],
    ],
    ['admin:reports_reportcategory_changelist', [['wp-admin/reports/reportcategory/', []]]],
    [
      'admin:reports_reportcategory_delete',
      [['wp-admin/reports/reportcategory/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:reports_reportcategory_history',
      [['wp-admin/reports/reportcategory/%(object_id)s/history/', ['object_id']]],
    ],
    ['admin:reports_reportpreview_add', [['wp-admin/reports/reportpreview/add/', []]]],
    [
      'admin:reports_reportpreview_change',
      [['wp-admin/reports/reportpreview/%(object_id)s/change/', ['object_id']]],
    ],
    ['admin:reports_reportpreview_changelist', [['wp-admin/reports/reportpreview/', []]]],
    [
      'admin:reports_reportpreview_delete',
      [['wp-admin/reports/reportpreview/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:reports_reportpreview_history',
      [['wp-admin/reports/reportpreview/%(object_id)s/history/', ['object_id']]],
    ],
    ['admin:reports_reporttemplate_add', [['wp-admin/reports/reporttemplate/add/', []]]],
    [
      'admin:reports_reporttemplate_change',
      [['wp-admin/reports/reporttemplate/%(object_id)s/change/', ['object_id']]],
    ],
    ['admin:reports_reporttemplate_changelist', [['wp-admin/reports/reporttemplate/', []]]],
    [
      'admin:reports_reporttemplate_delete',
      [['wp-admin/reports/reporttemplate/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:reports_reporttemplate_history',
      [['wp-admin/reports/reporttemplate/%(object_id)s/history/', ['object_id']]],
    ],
    ['admin:school_school_add', [['wp-admin/school/school/add/', []]]],
    [
      'admin:school_school_change',
      [['wp-admin/school/school/%(object_id)s/change/', ['object_id']]],
    ],
    ['admin:school_school_changelist', [['wp-admin/school/school/', []]]],
    [
      'admin:school_school_delete',
      [['wp-admin/school/school/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:school_school_history',
      [['wp-admin/school/school/%(object_id)s/history/', ['object_id']]],
    ],
    [
      'admin:score_transformations_csspartition_add',
      [['wp-admin/score_transformations/csspartition/add/', []]],
    ],
    [
      'admin:score_transformations_csspartition_change',
      [['wp-admin/score_transformations/csspartition/%(object_id)s/change/', ['object_id']]],
    ],
    [
      'admin:score_transformations_csspartition_changelist',
      [['wp-admin/score_transformations/csspartition/', []]],
    ],
    [
      'admin:score_transformations_csspartition_delete',
      [['wp-admin/score_transformations/csspartition/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:score_transformations_csspartition_history',
      [['wp-admin/score_transformations/csspartition/%(object_id)s/history/', ['object_id']]],
    ],
    [
      'admin:score_transformations_csspartitionscoretransformation_add',
      [['wp-admin/score_transformations/csspartitionscoretransformation/add/', []]],
    ],
    [
      'admin:score_transformations_csspartitionscoretransformation_change',
      [
        [
          'wp-admin/score_transformations/csspartitionscoretransformation/%(object_id)s/change/',
          ['object_id'],
        ],
      ],
    ],
    [
      'admin:score_transformations_csspartitionscoretransformation_changelist',
      [['wp-admin/score_transformations/csspartitionscoretransformation/', []]],
    ],
    [
      'admin:score_transformations_csspartitionscoretransformation_delete',
      [
        [
          'wp-admin/score_transformations/csspartitionscoretransformation/%(object_id)s/delete/',
          ['object_id'],
        ],
      ],
    ],
    [
      'admin:score_transformations_csspartitionscoretransformation_history',
      [
        [
          'wp-admin/score_transformations/csspartitionscoretransformation/%(object_id)s/history/',
          ['object_id'],
        ],
      ],
    ],
    [
      'admin:score_transformations_decimalscoretransformation_add',
      [['wp-admin/score_transformations/decimalscoretransformation/add/', []]],
    ],
    [
      'admin:score_transformations_decimalscoretransformation_change',
      [
        [
          'wp-admin/score_transformations/decimalscoretransformation/%(object_id)s/change/',
          ['object_id'],
        ],
      ],
    ],
    [
      'admin:score_transformations_decimalscoretransformation_changelist',
      [['wp-admin/score_transformations/decimalscoretransformation/', []]],
    ],
    [
      'admin:score_transformations_decimalscoretransformation_delete',
      [
        [
          'wp-admin/score_transformations/decimalscoretransformation/%(object_id)s/delete/',
          ['object_id'],
        ],
      ],
    ],
    [
      'admin:score_transformations_decimalscoretransformation_history',
      [
        [
          'wp-admin/score_transformations/decimalscoretransformation/%(object_id)s/history/',
          ['object_id'],
        ],
      ],
    ],
    [
      'admin:score_transformations_partitionscoretransformation_add',
      [['wp-admin/score_transformations/partitionscoretransformation/add/', []]],
    ],
    [
      'admin:score_transformations_partitionscoretransformation_change',
      [
        [
          'wp-admin/score_transformations/partitionscoretransformation/%(object_id)s/change/',
          ['object_id'],
        ],
      ],
    ],
    [
      'admin:score_transformations_partitionscoretransformation_changelist',
      [['wp-admin/score_transformations/partitionscoretransformation/', []]],
    ],
    [
      'admin:score_transformations_partitionscoretransformation_delete',
      [
        [
          'wp-admin/score_transformations/partitionscoretransformation/%(object_id)s/delete/',
          ['object_id'],
        ],
      ],
    ],
    [
      'admin:score_transformations_partitionscoretransformation_history',
      [
        [
          'wp-admin/score_transformations/partitionscoretransformation/%(object_id)s/history/',
          ['object_id'],
        ],
      ],
    ],
    [
      'admin:score_transformations_scorepartition_add',
      [['wp-admin/score_transformations/scorepartition/add/', []]],
    ],
    [
      'admin:score_transformations_scorepartition_change',
      [['wp-admin/score_transformations/scorepartition/%(object_id)s/change/', ['object_id']]],
    ],
    [
      'admin:score_transformations_scorepartition_changelist',
      [['wp-admin/score_transformations/scorepartition/', []]],
    ],
    [
      'admin:score_transformations_scorepartition_delete',
      [['wp-admin/score_transformations/scorepartition/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:score_transformations_scorepartition_history',
      [['wp-admin/score_transformations/scorepartition/%(object_id)s/history/', ['object_id']]],
    ],
    [
      'admin:score_transformations_scoretransformation_add',
      [['wp-admin/score_transformations/scoretransformation/add/', []]],
    ],
    [
      'admin:score_transformations_scoretransformation_change',
      [['wp-admin/score_transformations/scoretransformation/%(object_id)s/change/', ['object_id']]],
    ],
    [
      'admin:score_transformations_scoretransformation_changelist',
      [['wp-admin/score_transformations/scoretransformation/', []]],
    ],
    [
      'admin:score_transformations_scoretransformation_delete',
      [['wp-admin/score_transformations/scoretransformation/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:score_transformations_scoretransformation_history',
      [
        [
          'wp-admin/score_transformations/scoretransformation/%(object_id)s/history/',
          ['object_id'],
        ],
      ],
    ],
    [
      'admin:scores_unitstudentextrascorecategory_add',
      [['wp-admin/scores/unitstudentextrascorecategory/add/', []]],
    ],
    [
      'admin:scores_unitstudentextrascorecategory_change',
      [['wp-admin/scores/unitstudentextrascorecategory/%(object_id)s/change/', ['object_id']]],
    ],
    [
      'admin:scores_unitstudentextrascorecategory_changelist',
      [['wp-admin/scores/unitstudentextrascorecategory/', []]],
    ],
    [
      'admin:scores_unitstudentextrascorecategory_delete',
      [['wp-admin/scores/unitstudentextrascorecategory/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:scores_unitstudentextrascorecategory_history',
      [['wp-admin/scores/unitstudentextrascorecategory/%(object_id)s/history/', ['object_id']]],
    ],
    ['admin:sites_site_add', [['wp-admin/sites/site/add/', []]]],
    ['admin:sites_site_change', [['wp-admin/sites/site/%(object_id)s/change/', ['object_id']]]],
    ['admin:sites_site_changelist', [['wp-admin/sites/site/', []]]],
    ['admin:sites_site_delete', [['wp-admin/sites/site/%(object_id)s/delete/', ['object_id']]]],
    ['admin:sites_site_history', [['wp-admin/sites/site/%(object_id)s/history/', ['object_id']]]],
    ['admin:structure_level_add', [['wp-admin/structure/level/add/', []]]],
    [
      'admin:structure_level_change',
      [['wp-admin/structure/level/%(object_id)s/change/', ['object_id']]],
    ],
    ['admin:structure_level_changelist', [['wp-admin/structure/level/', []]]],
    [
      'admin:structure_level_delete',
      [['wp-admin/structure/level/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:structure_level_history',
      [['wp-admin/structure/level/%(object_id)s/history/', ['object_id']]],
    ],
    ['admin:structure_stage_add', [['wp-admin/structure/stage/add/', []]]],
    [
      'admin:structure_stage_change',
      [['wp-admin/structure/stage/%(object_id)s/change/', ['object_id']]],
    ],
    ['admin:structure_stage_changelist', [['wp-admin/structure/stage/', []]]],
    [
      'admin:structure_stage_delete',
      [['wp-admin/structure/stage/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:structure_stage_history',
      [['wp-admin/structure/stage/%(object_id)s/history/', ['object_id']]],
    ],
    ['admin:userprofiles_medicines_add', [['wp-admin/userprofiles/medicines/add/', []]]],
    [
      'admin:userprofiles_medicines_change',
      [['wp-admin/userprofiles/medicines/%(object_id)s/change/', ['object_id']]],
    ],
    ['admin:userprofiles_medicines_changelist', [['wp-admin/userprofiles/medicines/', []]]],
    [
      'admin:userprofiles_medicines_delete',
      [['wp-admin/userprofiles/medicines/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:userprofiles_medicines_history',
      [['wp-admin/userprofiles/medicines/%(object_id)s/history/', ['object_id']]],
    ],
    ['admin:userprofiles_studentprofile_add', [['wp-admin/userprofiles/studentprofile/add/', []]]],
    [
      'admin:userprofiles_studentprofile_change',
      [['wp-admin/userprofiles/studentprofile/%(object_id)s/change/', ['object_id']]],
    ],
    [
      'admin:userprofiles_studentprofile_changelist',
      [['wp-admin/userprofiles/studentprofile/', []]],
    ],
    [
      'admin:userprofiles_studentprofile_delete',
      [['wp-admin/userprofiles/studentprofile/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:userprofiles_studentprofile_history',
      [['wp-admin/userprofiles/studentprofile/%(object_id)s/history/', ['object_id']]],
    ],
    ['admin:userprofiles_teacherprofile_add', [['wp-admin/userprofiles/teacherprofile/add/', []]]],
    [
      'admin:userprofiles_teacherprofile_change',
      [['wp-admin/userprofiles/teacherprofile/%(object_id)s/change/', ['object_id']]],
    ],
    [
      'admin:userprofiles_teacherprofile_changelist',
      [['wp-admin/userprofiles/teacherprofile/', []]],
    ],
    [
      'admin:userprofiles_teacherprofile_delete',
      [['wp-admin/userprofiles/teacherprofile/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:userprofiles_teacherprofile_history',
      [['wp-admin/userprofiles/teacherprofile/%(object_id)s/history/', ['object_id']]],
    ],
    ['admin:userprofiles_tutorprofile_add', [['wp-admin/userprofiles/tutorprofile/add/', []]]],
    [
      'admin:userprofiles_tutorprofile_change',
      [['wp-admin/userprofiles/tutorprofile/%(object_id)s/change/', ['object_id']]],
    ],
    ['admin:userprofiles_tutorprofile_changelist', [['wp-admin/userprofiles/tutorprofile/', []]]],
    [
      'admin:userprofiles_tutorprofile_delete',
      [['wp-admin/userprofiles/tutorprofile/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:userprofiles_tutorprofile_history',
      [['wp-admin/userprofiles/tutorprofile/%(object_id)s/history/', ['object_id']]],
    ],
    ['admin:users_customuser_add', [['wp-admin/users/customuser/add/', []]]],
    [
      'admin:users_customuser_change',
      [['wp-admin/users/customuser/%(object_id)s/change/', ['object_id']]],
    ],
    ['admin:users_customuser_changelist', [['wp-admin/users/customuser/', []]]],
    [
      'admin:users_customuser_delete',
      [['wp-admin/users/customuser/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:users_customuser_history',
      [['wp-admin/users/customuser/%(object_id)s/history/', ['object_id']]],
    ],
    ['admin:users_dropmethod_add', [['wp-admin/users/dropmethod/add/', []]]],
    [
      'admin:users_dropmethod_change',
      [['wp-admin/users/dropmethod/%(object_id)s/change/', ['object_id']]],
    ],
    ['admin:users_dropmethod_changelist', [['wp-admin/users/dropmethod/', []]]],
    [
      'admin:users_dropmethod_delete',
      [['wp-admin/users/dropmethod/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:users_dropmethod_history',
      [['wp-admin/users/dropmethod/%(object_id)s/history/', ['object_id']]],
    ],
    ['admin:users_pickupmethod_add', [['wp-admin/users/pickupmethod/add/', []]]],
    [
      'admin:users_pickupmethod_change',
      [['wp-admin/users/pickupmethod/%(object_id)s/change/', ['object_id']]],
    ],
    ['admin:users_pickupmethod_changelist', [['wp-admin/users/pickupmethod/', []]]],
    [
      'admin:users_pickupmethod_delete',
      [['wp-admin/users/pickupmethod/%(object_id)s/delete/', ['object_id']]],
    ],
    [
      'admin:users_pickupmethod_history',
      [['wp-admin/users/pickupmethod/%(object_id)s/history/', ['object_id']]],
    ],
    [
      'admin:view_on_site',
      [['wp-admin/r/%(content_type_id)s/%(object_id)s/', ['content_type_id', 'object_id']]],
    ],
    ['admin_cycles', [['admin-cycles/', []]]],
    ['all_cycles', [['api/cycle/cycles/', []]]],
    ['anual_scores_report_for_mineduc', [['cycle/report/anual-report-for-mineduc', []]]],
    [
      'api-root',
      [
        ['api/v3/.%(format)s', ['format']],
        ['api/v3/', []],
        ['api/v2/.%(format)s', ['format']],
        ['api/v2/', []],
        ['api/v2/.%(format)s', ['format']],
        ['api/v2/', []],
        ['api/notifications/.%(format)s', ['format']],
        ['api/notifications/', []],
        ['public-api/v1/.%(format)s', ['format']],
        ['public-api/v1/', []],
      ],
    ],
    ['api_forgot_password', [['api/users/forgot-password/', []]]],
    ['api_get_insolvent_payment_responsable_ids', [['api/users/get-insolvent-users/', []]]],
    ['api_lock_insolvent_users', [['api/users/lock-insolvent-users/', []]]],
    ['api_unlock_insolvent_users', [['api/users/unlock-insolvent-users/', []]]],
    [
      'assign_achievement',
      [['achievements/assign/%(achievement_pk)s/user/%(user_pk)s/', ['achievement_pk', 'user_pk']]],
    ],
    ['assign_achievements', [['achievements/assign/', []]]],
    [
      'assign_student_from_other_level',
      [['cycle/section/%(section_pk)s/assign-student-from-other-level/', ['section_pk']]],
    ],
    ['assistance_for_date', [['api/assistance/assistance-for-date', []]]],
    [
      'assistance_get_formset_for_structure',
      [
        [
          'assistance/student-assistance-formset-for-structure/%(content_type_pk)s/%(element_pk)s/',
          ['content_type_pk', 'element_pk'],
        ],
      ],
    ],
    ['assistance_save', [['assistance/save/', []]]],
    ['assistance_save_for_user', [['assistance/save-for-user/', []]]],
    ['assistance_search', [['api/assistance/assistance-search', []]]],
    ['auto_login', [['user/auto-login/', []]]],
    ['blocked_school', [['school/blocked/', []]]],
    ['bulk_create_sections', [['cycle/bulk-create-sections/', []]]],
    ['calendar_dashboard', [['dashboard/calendar/', []]]],
    [
      'cancel_google_service',
      [['dashboard/student/%(user_pk)s/cancel-google-service/', ['user_pk']]],
    ],
    ['cancel_meeting', [['meetings/meetings/cancel/%(meeting_pk)s/', ['meeting_pk']]]],
    ['category_folder_create', [['files/category-folder/create/', []]]],
    ['category_folder_delete', [['files/category-folder/delete/%(category_pk)s', ['category_pk']]]],
    ['category_folder_update', [['files/category-folder/update/', []]]],
    [
      'change_calculation_type',
      [['api/cycle/cycles/course/%(course_pk)s/change-calculation-type/', ['course_pk']]],
    ],
    ['change_cycle', [['change-cycle/', []]]],
    [
      'change_cycle_stage_backend_configuration',
      [['stage-backend/%(stage_id)s/configuration/change-cycle/', ['stage_id']]],
    ],
    [
      'change_extra_curricular_supervisor',
      [
        [
          'cycle/extra-curricular/%(extra_curricular_pk)s/supervisors/change-supervisor/',
          ['extra_curricular_pk'],
        ],
      ],
    ],
    ['change_final_grade', [['cycle/change-final-grade/%(student_pk)s/', ['student_pk']]]],
    ['change_level', [['api/cycle/level/change-level/', []]]],
    [
      'change_next_level',
      [['api/userprofiles/students/%(student_pk)s/change-next-level/', ['student_pk']]],
    ],
    ['change_password', [['user/%(username)s/change-password/', ['username']]]],
    [
      'change_ponderation',
      [['api/cycle/cycles/course/%(subrel_pk)s/save-ponderation/', ['subrel_pk']]],
    ],
    ['change_profile_picture', [['user/%(username)s/change-profile-picture/', ['username']]]],
    ['change_sections', [['api/cycle/sections/change-sections/', []]]],
    ['change_user_language', [['user/student/%(user_pk)s/change-user-language/', ['user_pk']]]],
    ['comments-approve', [['comments/approve/%(comment_id)s/', ['comment_id']]]],
    ['comments-approve-done', [['comments/approved/', []]]],
    ['comments-comment-done', [['comments/posted/', []]]],
    ['comments-delete', [['comments/delete/%(comment_id)s/', ['comment_id']]]],
    ['comments-delete-done', [['comments/deleted/', []]]],
    ['comments-flag', [['comments/flag/%(comment_id)s/', ['comment_id']]]],
    ['comments-flag-done', [['comments/flagged/', []]]],
    ['comments-post-comment', [['comments/post/', []]]],
    ['comments-url-redirect', [['comments/cr/%(_0)s/%(_1)s/', ['_0', '_1']]]],
    ['configuration_dashboard', [['dashboard/configuration/', []]]],
    [
      'configuration_score_card_v2_by_cycle_and_student',
      [
        [
          'api/cycle/%(cycle_pk)s/%(content_type_pk)s/%(element_pk)s/configuration-score_card/',
          ['cycle_pk', 'content_type_pk', 'element_pk'],
        ],
      ],
    ],
    ['confirm_google_meeting_permission', [['meetings/google-meetings/confirm-permission/', []]]],
    ['confirm_zoom_meeting_permission', [['meetings/zoom/confirm-permission/', []]]],
    ['course_add_section', [['cycle/course/%(course_pk)s/add-section/', ['course_pk']]]],
    ['course_backend', [['course-backend/%(level_id)s/%(course_id)s/', ['level_id', 'course_id']]]],
    ['create_google_meeting', [['meetings/google/create/', []]]],
    [
      'create_omnisection_for_level',
      [['cycle/omni-section/create-for-level/%(level_pk)s/', ['level_pk']]],
    ],
    ['create_report', [['reports/create-report/', []]]],
    ['create_zoom_meeting', [['meetings/zoom/create/', []]]],
    ['crop_logo', [['school/crop-logo/', []]]],
    ['custom_user_search', [['search/custom-user-search/', []]]],
    [
      'customuser-activities',
      [
        ['api/v2/users/%(pk)s/activities.%(format)s', ['pk', 'format']],
        ['api/v2/users/%(pk)s/activities/', ['pk']],
        ['api/v2/users/%(pk)s/activities.%(format)s', ['pk', 'format']],
        ['api/v2/users/%(pk)s/activities/', ['pk']],
      ],
    ],
    [
      'customuser-detail',
      [
        ['api/v3/custom-users/%(pk)s.%(format)s', ['pk', 'format']],
        ['api/v3/custom-users/%(pk)s/', ['pk']],
        ['api/v2/users/%(pk)s.%(format)s', ['pk', 'format']],
        ['api/v2/users/%(pk)s/', ['pk']],
        ['api/v2/users/%(pk)s.%(format)s', ['pk', 'format']],
        ['api/v2/users/%(pk)s/', ['pk']],
      ],
    ],
    [
      'customuser-events',
      [
        ['api/v3/custom-users/%(pk)s/events.%(format)s', ['pk', 'format']],
        ['api/v3/custom-users/%(pk)s/events/', ['pk']],
      ],
    ],
    [
      'customuser-get-user-data',
      [
        ['api/v3/custom-users/get-user-data.%(format)s', ['format']],
        ['api/v3/custom-users/get-user-data/', []],
      ],
    ],
    [
      'customuser-groups',
      [
        ['api/v3/custom-users/%(pk)s/groups.%(format)s', ['pk', 'format']],
        ['api/v3/custom-users/%(pk)s/groups/', ['pk']],
        ['api/v2/users/%(pk)s/groups.%(format)s', ['pk', 'format']],
        ['api/v2/users/%(pk)s/groups/', ['pk']],
        ['api/v2/users/%(pk)s/groups.%(format)s', ['pk', 'format']],
        ['api/v2/users/%(pk)s/groups/', ['pk']],
      ],
    ],
    [
      'customuser-historic-events',
      [
        ['api/v3/custom-users/%(pk)s/historic-events.%(format)s', ['pk', 'format']],
        ['api/v3/custom-users/%(pk)s/historic-events/', ['pk']],
      ],
    ],
    [
      'customuser-list',
      [
        ['api/v3/custom-users.%(format)s', ['format']],
        ['api/v3/custom-users/', []],
        ['api/v2/users.%(format)s', ['format']],
        ['api/v2/users/', []],
        ['api/v2/users.%(format)s', ['format']],
        ['api/v2/users/', []],
      ],
    ],
    [
      'customuser-profiles',
      [
        ['api/v3/custom-users/%(pk)s/profiles.%(format)s', ['pk', 'format']],
        ['api/v3/custom-users/%(pk)s/profiles/', ['pk']],
      ],
    ],
    [
      'customuser-recover-password',
      [
        ['api/v3/custom-users/recover-password.%(format)s', ['format']],
        ['api/v3/custom-users/recover-password/', []],
      ],
    ],
    [
      'customuserachievementassignation-detail',
      [
        ['api/v3/custom-user-achievement-assignation/%(pk)s.%(format)s', ['pk', 'format']],
        ['api/v3/custom-user-achievement-assignation/%(pk)s/', ['pk']],
      ],
    ],
    [
      'customuserachievementassignation-list',
      [
        ['api/v3/custom-user-achievement-assignation.%(format)s', ['format']],
        ['api/v3/custom-user-achievement-assignation/', []],
      ],
    ],
    [
      'customuserdisciplinefaultassignation-detail',
      [
        ['api/v3/custom-user-discipline-fault-assignations/%(pk)s.%(format)s', ['pk', 'format']],
        ['api/v3/custom-user-discipline-fault-assignations/%(pk)s/', ['pk']],
      ],
    ],
    [
      'customuserdisciplinefaultassignation-list',
      [
        ['api/v3/custom-user-discipline-fault-assignations.%(format)s', ['format']],
        ['api/v3/custom-user-discipline-fault-assignations/', []],
      ],
    ],
    [
      'cycle-active',
      [
        ['api/v3/cycles/active.%(format)s', ['format']],
        ['api/v3/cycles/active/', []],
      ],
    ],
    [
      'cycle-detail',
      [
        ['api/v3/cycles/%(pk)s.%(format)s', ['pk', 'format']],
        ['api/v3/cycles/%(pk)s/', ['pk']],
      ],
    ],
    [
      'cycle-list',
      [
        ['api/v3/cycles.%(format)s', ['format']],
        ['api/v3/cycles/', []],
      ],
    ],
    [
      'cycle_score_registers',
      [
        [
          'api/cycle/cycles/%(cycle_pk)s/student-score-registers/%(student_pk)s/',
          ['cycle_pk', 'student_pk'],
        ],
      ],
    ],
    [
      'cycle_structure_section_names',
      [
        [
          'api/cycle/%(cycle_pk)s/%(content_type_pk)s/%(element_pk)s/section-names/',
          ['cycle_pk', 'content_type_pk', 'element_pk'],
        ],
      ],
    ],
    [
      'cycle_structure_sections_units_names',
      [
        [
          'api/cycle/%(cycle_pk)s/%(content_type_pk)s/%(element_pk)s/sections-units-names/',
          ['cycle_pk', 'content_type_pk', 'element_pk'],
        ],
      ],
    ],
    ['dashboard', [['dashboard/', []]]],
    [
      'dayuserassistance-detail',
      [
        ['api/v3/day-user-assistances/%(pk)s.%(format)s', ['pk', 'format']],
        ['api/v3/day-user-assistances/%(pk)s/', ['pk']],
      ],
    ],
    [
      'dayuserassistance-list',
      [
        ['api/v3/day-user-assistances.%(format)s', ['format']],
        ['api/v3/day-user-assistances/', []],
      ],
    ],
    [
      'deassign_achievement',
      [['achievements/deassign/%(achievementassignationpk)s/', ['achievementassignationpk']]],
    ],
    [
      'delete_comment',
      [['cycle/section/comments/%(comment_pk)s/delete-comment-planning/', ['comment_pk']]],
    ],
    ['delete_course', [['structure/delete-course/%(course_pk)s/', ['course_pk']]]],
    [
      'delete_course_supervisor',
      [
        [
          'cycle/course/%(course_pk)s/supervisors/%(supervisor_pk)s/delete/',
          ['course_pk', 'supervisor_pk'],
        ],
      ],
    ],
    ['delete_cycle', [['cycle/delete-cycle/%(cycle_pk)s/', ['cycle_pk']]]],
    [
      'delete_extra_curricular_course',
      [['cycle/extra-curricular/%(extra_curricular_pk)s/delete/', ['extra_curricular_pk']]],
    ],
    [
      'delete_extra_curricular_supervisor',
      [
        [
          'cycle/extra-curricular/%(extra_curricular_pk)s/supervisors/%(supervisor_pk)s/delete/',
          ['extra_curricular_pk', 'supervisor_pk'],
        ],
      ],
    ],
    ['delete_gradecell', [['gradecell/%(gradecell_pk)s/delete/', ['gradecell_pk']]]],
    ['delete_level', [['structure/delete-level/%(level_pk)s/', ['level_pk']]]],
    [
      'delete_level_supervisor',
      [
        [
          'structure/level/%(level_id)s/supervisors/%(supervisor_pk)s/delete/',
          ['level_id', 'supervisor_pk'],
        ],
      ],
    ],
    ['delete_levelgroup', [['levelgroup/delete/%(levelgroup_pk)s/', ['levelgroup_pk']]]],
    [
      'delete_levelgroup_supervisor',
      [
        [
          'levelgroup/stage/%(levelgroup_id)s/supervisors/%(supervisor_pk)s/delete/',
          ['levelgroup_id', 'supervisor_pk'],
        ],
      ],
    ],
    ['delete_model_unit', [['model-unit/%(gradecell_pk)s/delete/', ['gradecell_pk']]]],
    ['delete_report', [['reports/delete/%(report_pk)s/', ['report_pk']]]],
    ['delete_schedule', [['profile/teacher/%(username)s/delete-schedule', ['username']]]],
    ['delete_section', [['cycle/section/delete/%(section_pk)s/', ['section_pk']]]],
    [
      'delete_section_announcement',
      [
        [
          'cycle/section/%(section_pk)s/annoucements/%(announcement_pk)s/delete',
          ['section_pk', 'announcement_pk'],
        ],
      ],
    ],
    [
      'delete_section_announcement_comment',
      [['cycle/annoucements-comments/%(comment_pk)s/delete', ['comment_pk']]],
    ],
    [
      'delete_section_supervisor',
      [
        [
          'cycle/section/delete/%(section_pk)s/supervisors/%(supervisor_pk)s/',
          ['section_pk', 'supervisor_pk'],
        ],
      ],
    ],
    ['delete_signature', [['profile/teacher/%(username)s/delete-signature', ['username']]]],
    ['delete_stage', [['structure/delete-stage/%(stage_pk)s/', ['stage_pk']]]],
    [
      'delete_stage_supervisor',
      [
        [
          'structure/stage/%(stage_id)s/supervisors/%(supervisor_pk)s/delete/',
          ['stage_id', 'supervisor_pk'],
        ],
      ],
    ],
    [
      'delete_student_contact',
      [['profile/student/%(username)s/delete-student-contact/', ['username']]],
    ],
    [
      'delete_student_extra_score',
      [
        [
          'scores/delete-student-extra-score/%(student_extra_score_pk)s/',
          ['student_extra_score_pk'],
        ],
      ],
    ],
    ['delete_unit', [['unit/%(gradecell_pk)s/delete/', ['gradecell_pk']]]],
    ['delete_user', [['user/%(username)s/delete-user/', ['username']]]],
    ['direct_accesses', [['files/direct-accesses/%(user_pk)s', ['user_pk']]]],
    [
      'disable_supervised_temporal_access',
      [['user/%(user_pk)s/disable-supervised-temporal-access', ['user_pk']]],
    ],
    ['discipline_assign_report', [['discipline/assign-report/', []]]],
    [
      'discipline_delete_fault',
      [['discipline/delete-fault/%(fault_assignation_pk)s/', ['fault_assignation_pk']]],
    ],
    [
      'discipline_notify_fault',
      [['discipline/notify-fault/(%(fault_assignation_pk)s/', ['fault_assignation_pk']]],
    ],
    ['discipline_student_expedient', [['discipline/student-expedient/%(user_pk)s', ['user_pk']]]],
    ['dlink_cam_stream', [['dlink/cam/%(cam_pk)s/stream/', ['cam_pk']]]],
    ['document_api_view', [['api/v3/docs/documents/', []]]],
    ['document_detail_api_view', [['api/v3/docs/documents/%(document_id)s/', ['document_id']]]],
    [
      'download_activities_excel',
      [['cycle/section/%(section_pk)s/download-activities-excel/', ['section_pk']]],
    ],
    ['download_calendar', [['activities/events/download-calendar/%(user_pk)s/', ['user_pk']]]],
    [
      'edit_comment_planning',
      [['cycle/section/planning/comments/%(comment_pk)s/edit-comment-planning/', ['comment_pk']]],
    ],
    ['edit_course', [['cycle/course/%(course_pk)s/edit/', ['course_pk']]]],
    ['edit_course_supervisor', [['cycle/course/%(course_pk)s/change-supervisor/', ['course_pk']]]],
    [
      'edit_discipline_fault',
      [['discipline/edit-fault/%(fault_assignation_pk)s/', ['fault_assignation_pk']]],
    ],
    [
      'edit_extra_curricular_course',
      [['cycle/extra-curricular/%(extra_curricular_pk)s/edit/', ['extra_curricular_pk']]],
    ],
    ['edit_gradecell', [['gradecell/%(gradecell_pk)s/edit/', ['gradecell_pk']]]],
    ['edit_level', [['structure/level/%(level_id)s', ['level_id']]]],
    ['edit_level_add_course', [['structure/level/%(level_id)s/add-course/', ['level_id']]]],
    ['edit_level_add_levelgroup', [['structure/level/%(level_id)s/add-group/', ['level_id']]]],
    ['edit_level_supervisor', [['structure/level/%(level_id)s/change-supervisor/', ['level_id']]]],
    ['edit_levelgroup', [['levelgroup/edit/%(levelgroup_pk)s/', ['levelgroup_pk']]]],
    ['edit_section', [['cycle/section/edit/%(section_pk)s/', ['section_pk']]]],
    ['edit_staff_profile', [['profile/staff/%(username)s/edit/', ['username']]]],
    ['edit_stage', [['structure/stage/%(stage_id)s/edit/', ['stage_id']]]],
    ['edit_stage_add_level', [['structure/stage/%(stage_id)s/add-level/', ['stage_id']]]],
    ['edit_stage_supervisor', [['structure/stage/%(stage_id)s/change-supervisor/', ['stage_id']]]],
    [
      'edit_student_contact',
      [['profile/student/%(student_contact_pk)s/edit-student-contact/', ['student_contact_pk']]],
    ],
    ['edit_teacher_profile', [['profile/teacher/%(username)s/edit', ['username']]]],
    ['edit_tutor_profile', [['profile/tutor/%(username)s/edit', ['username']]]],
    ['edit_user_basic', [['user/%(username)s/basic-edit/', ['username']]]],
    [
      'edit_user_student_basic',
      [['user/%(username)s/basic-edit-student/%(subsection)s', ['username', 'subsection']]],
    ],
    ['enable_supervised_temporal_access', [['api/users/enable-supervised-temporal-access/', []]]],
    ['end_cycle', [['end-cycle/', []]]],
    ['end_meeting', [['meetings/meetings/end/%(meeting_pk)s/', ['meeting_pk']]]],
    ['end_meeting_external', [['meetings/meetings/end-external/', []]]],
    ['enroll_or_unenroll_student', [['odoo/student/enroll-or-unenroll/', []]]],
    [
      'event-detail',
      [
        ['api/v3/events/%(pk)s.%(format)s', ['pk', 'format']],
        ['api/v3/events/%(pk)s/', ['pk']],
      ],
    ],
    [
      'event-list',
      [
        ['api/v3/events.%(format)s', ['format']],
        ['api/v3/events/', []],
      ],
    ],
    [
      'exam-detail',
      [
        ['api/v3/exams/%(pk)s.%(format)s', ['pk', 'format']],
        ['api/v3/exams/%(pk)s/', ['pk']],
      ],
    ],
    [
      'exam-list',
      [
        ['api/v3/exams.%(format)s', ['format']],
        ['api/v3/exams/', []],
      ],
    ],
    [
      'extra_curricular_activate_unit',
      [['cycle/extra-curricular/%(extra_curricular_id)s/activate-unit/', ['extra_curricular_id']]],
    ],
    [
      'extra_curricular_allocate',
      [
        [
          'api/cycle/extra-curricular/%(extra_curricular_pk)s/stages/allocate',
          ['extra_curricular_pk'],
        ],
      ],
    ],
    [
      'extra_curricular_allocate_level',
      [
        [
          'api/cycle/extra-curricular/%(extra_curricular_pk)s/level/%(level_pk)s/allocate',
          ['extra_curricular_pk', 'level_pk'],
        ],
      ],
    ],
    [
      'extra_curricular_allocate_stage',
      [
        [
          'api/cycle/extra-curricular/%(extra_curricular_pk)s/stage/%(stage_pk)s/allocate',
          ['extra_curricular_pk', 'stage_pk'],
        ],
      ],
    ],
    [
      'extra_curricular_backend_default_score_distribution',
      [
        [
          'extra-curricular-backend/%(extra_curricular_id)s/default-score-distribution/',
          ['extra_curricular_id'],
        ],
      ],
    ],
    [
      'extra_curricular_backend_default_score_distribution_edit',
      [
        [
          'extra-curricular-backend/%(extra_curricular_id)s/default-score-distribution/edit/',
          ['extra_curricular_id'],
        ],
      ],
    ],
    [
      'extra_curricular_deallocate',
      [['api/cycle/extra-curricular/%(extra_curricular_pk)s/deallocate', ['extra_curricular_pk']]],
    ],
    [
      'fcmdevice-detail',
      [
        ['api/v3/devices/%(registration_id)s.%(format)s', ['registration_id', 'format']],
        ['api/v3/devices/%(registration_id)s/', ['registration_id']],
        ['api/v2/devices/%(registration_id)s.%(format)s', ['registration_id', 'format']],
        ['api/v2/devices/%(registration_id)s/', ['registration_id']],
        ['api/v2/devices/%(registration_id)s.%(format)s', ['registration_id', 'format']],
        ['api/v2/devices/%(registration_id)s/', ['registration_id']],
      ],
    ],
    [
      'fcmdevice-list',
      [
        ['api/v3/devices.%(format)s', ['format']],
        ['api/v3/devices/', []],
        ['api/v2/devices.%(format)s', ['format']],
        ['api/v2/devices/', []],
        ['api/v2/devices.%(format)s', ['format']],
        ['api/v2/devices/', []],
      ],
    ],
    ['file_create', [['api/files/file/create', []]]],
    ['file_resource_create', [['files/files/create/', []]]],
    ['file_resource_delete', [['files/files/delete/%(file_pk)s/', ['file_pk']]]],
    ['file_resource_update', [['files/files/update/', []]]],
    ['files_dashboard', [['dashboard/files/', []]]],
    ['finance_promotional', [['dashboard/finance_promotional/', []]]],
    ['forgot_password', [['user/forgot-password/', []]]],
    [
      'gate-add-exit',
      [
        ['api/v2/gates/%(pk)s/add_exit.%(format)s', ['pk', 'format']],
        ['api/v2/gates/%(pk)s/add_exit/', ['pk']],
        ['api/v2/gates/%(pk)s/add_exit.%(format)s', ['pk', 'format']],
        ['api/v2/gates/%(pk)s/add_exit/', ['pk']],
      ],
    ],
    [
      'gate-check',
      [
        ['api/v2/gates/%(pk)s/check.%(format)s', ['pk', 'format']],
        ['api/v2/gates/%(pk)s/check/', ['pk']],
        ['api/v2/gates/%(pk)s/check.%(format)s', ['pk', 'format']],
        ['api/v2/gates/%(pk)s/check/', ['pk']],
      ],
    ],
    [
      'gate-detail',
      [
        ['api/v2/gates/%(pk)s.%(format)s', ['pk', 'format']],
        ['api/v2/gates/%(pk)s/', ['pk']],
        ['api/v2/gates/%(pk)s.%(format)s', ['pk', 'format']],
        ['api/v2/gates/%(pk)s/', ['pk']],
      ],
    ],
    [
      'gate-list',
      [
        ['api/v2/gates.%(format)s', ['format']],
        ['api/v2/gates/', []],
        ['api/v2/gates.%(format)s', ['format']],
        ['api/v2/gates/', []],
      ],
    ],
    [
      'gate-student',
      [
        ['api/v2/gates/%(pk)s/student.%(format)s', ['pk', 'format']],
        ['api/v2/gates/%(pk)s/student/', ['pk']],
        ['api/v2/gates/%(pk)s/student.%(format)s', ['pk', 'format']],
        ['api/v2/gates/%(pk)s/student/', ['pk']],
      ],
    ],
    ['gen_certificates', [['gen-certificates/', []]]],
    ['generate_document', [['api/v3/docs/document/%(document_id)s/generate/', ['document_id']]]],
    ['generate_download_url', [['api/v3/docs/blob/generate-download-url/', []]]],
    ['generate_password', [['api/users/generate-password/', []]]],
    ['generate_upload_url', [['api/v3/docs/blob/generate-upload-url/', []]]],
    ['get_all_levels', [['api/structure/levels/all', []]]],
    [
      'get_all_sections_from_student',
      [['api/userprofiles/students/%(student_pk)s/sections/', ['student_pk']]],
    ],
    [
      'get_allowed_customization_forms',
      [['api/reports/data-extractors/%(pk)s/get-allowed-customization-forms', ['pk']]],
    ],
    [
      'get_allowed_entry_forms',
      [['api/reports/data-extractors/%(pk)s/get-allowed-entry-forms', ['pk']]],
    ],
    [
      'get_assigned_students_extra_curricular_course',
      [
        [
          'api/cycle/extra-curricular/%(extra_curricular_pk)s/assigned-students',
          ['extra_curricular_pk'],
        ],
      ],
    ],
    [
      'get_customization_form',
      [
        [
          'reports/get-customization-form/%(report_pk)s/%(content_type_pk)s/%(element_pk)s/',
          ['report_pk', 'content_type_pk', 'element_pk'],
        ],
      ],
    ],
    ['get_entry_form', [['reports/%(report_pk)s/entry-form', ['report_pk']]]],
    ['get_level_courses', [['api/structure/levels/%(level_pk)s/courses', ['level_pk']]]],
    [
      'get_official_sections_level',
      [['api/structure/levels/%(level_pk)s/official-sections', ['level_pk']]],
    ],
    ['get_school_structure', [['api/structure/get-school-structure/', []]]],
    ['get_sections_level', [['api/structure/levels/%(level_pk)s/sections', ['level_pk']]]],
    ['get_student_score_respect_gradecell', [['scores/get-student-score-respect-gradecell/', []]]],
    ['get_students_by_levels', [['api/userprofiles/students/levels/', []]]],
    ['get_students_from_level', [['api/userprofiles/students/levels/%(level_pk)s/', ['level_pk']]]],
    ['get_students_from_stage', [['api/userprofiles/students/stage/(%(stage_pk)s/', ['stage_pk']]]],
    [
      'get_supervisor_suggestions',
      [['api/structure/level/%(level_id)s/get-supervisor-suggestions/', ['level_id']]],
    ],
    ['get_tutor_information', [['profile/tutor/%(tutor_pk)s/get-information/', ['tutor_pk']]]],
    ['gradecell_children', [['children/%(gradecell_pk)s/', ['gradecell_pk']]]],
    [
      'health_check',
      [
        ['api/v3/docs/health-check/', []],
        ['api/v3/backoffice/health-check/', []],
      ],
    ],
    ['health_check:health_check_home', [['health-check/', []]]],
    [
      'homework-detail',
      [
        ['api/v3/homeworks/%(pk)s.%(format)s', ['pk', 'format']],
        ['api/v3/homeworks/%(pk)s/', ['pk']],
      ],
    ],
    [
      'homework-list',
      [
        ['api/v3/homeworks.%(format)s', ['format']],
        ['api/v3/homeworks/', []],
      ],
    ],
    [
      'import_stage_backend_configuration',
      [['stage-backend/%(stage_id)s/import-configuration/', ['stage_id']]],
    ],
    [
      'import_unit_distribution_to',
      [['cycle/unit/import-distribution-to/%(edited_unit_pk)s/', ['edited_unit_pk']]],
    ],
    ['inactive_staff_search', [['search/inactive-staff-search/', []]]],
    ['inactive_students_search', [['search/inactive-students-search/', []]]],
    ['index', [['', []]]],
    [
      'infer_teacher_grade_activity_submission_dashboard',
      [['dashboard/teacher/grade-activity/%(activity_pk)s/submission/', ['activity_pk']]],
    ],
    ['is_changing_cycle', [['api/school/is_changing_cycle/', []]]],
    [
      'javascript-catalog',
      [
        ['jsi18n/', []],
        ['jsi18n/', []],
      ],
    ],
    [
      'join_meeting_attendee',
      [['meetings/meetings/%(meeting_pk)s/join-meeting-attendee/', ['meeting_pk']]],
    ],
    [
      'join_meeting_moderator',
      [['meetings/meetings/%(meeting_pk)s/join-meeting-moderator/', ['meeting_pk']]],
    ],
    ['js_reverse', [['jsreverse.json', []]]],
    ['language_configuration_dashboard', [['dashboard/configuration/language/', []]]],
    ['last_assistance_date', [['api/assistance/last-assistance-date', []]]],
    [
      'level-detail',
      [
        ['api/v3/levels/%(pk)s.%(format)s', ['pk', 'format']],
        ['api/v3/levels/%(pk)s/', ['pk']],
      ],
    ],
    [
      'level-list',
      [
        ['api/v3/levels.%(format)s', ['format']],
        ['api/v3/levels/', []],
      ],
    ],
    ['level_activate_unit', [['structure/level/(%(level_id)s/activate-unit/', ['level_id']]]],
    ['level_add_attitudinal', [['level-backend/%(level_id)s/add-attitudinal/', ['level_id']]]],
    ['level_add_category', [['attitudinal/categories/%(level_id)s/add/', ['level_id']]]],
    ['level_backend', [['level-backend/%(level_id)s/', ['level_id']]]],
    [
      'level_backend_attitudinal_configuration',
      [['level-backend/%(level_id)s/attitudinal-configuration/', ['level_id']]],
    ],
    [
      'level_backend_attitudinal_configuration_edit',
      [
        [
          'level-backend/%(level_id)s/attitudinal-configuration/edit/%(selected_gradecell_pk)s',
          ['level_id', 'selected_gradecell_pk'],
        ],
      ],
    ],
    ['level_backend_configuration', [['level-backend/%(level_id)s/configuration/', ['level_id']]]],
    [
      'level_backend_default_score_distribution',
      [['level-backend/%(level_id)s/default-score-distribution/', ['level_id']]],
    ],
    [
      'level_backend_default_score_distribution_edit',
      [
        [
          'level-backend/%(level_id)s/default-score-distribution/edit/%(selected_gradecell_pk)s/',
          ['level_id', 'selected_gradecell_pk'],
        ],
        ['level-backend/%(level_id)s/default-score-distribution/edit/', ['level_id']],
      ],
    ],
    ['level_backend_levelgroups', [['level-backend/%(level_id)s/groups/', ['level_id']]]],
    [
      'level_backend_main_sections',
      [['level-backend/%(level_pk)s/official-section/', ['level_pk']]],
    ],
    ['level_backend_sections', [['level-backend/%(level_id)s/sections/', ['level_id']]]],
    [
      'level_backend_unit_configuration',
      [['level-backend/%(level_id)s/unit-coniguration/', ['level_id']]],
    ],
    [
      'level_delete_attitudinal',
      [
        [
          'level-backend/%(level_id)s/attitudinal-configuration/delete/%(attitudinal_pk)s',
          ['level_id', 'attitudinal_pk'],
        ],
      ],
    ],
    [
      'level_delete_attitudinal_category',
      [['attitudinal/attitudinal-category/delete/%(category_pk)s', ['category_pk']]],
    ],
    [
      'level_edit_attitudinal',
      [
        [
          'level-backend/%(level_id)s/attitudinal-configuration/edit/%(attitudinal_pk)s',
          ['level_id', 'attitudinal_pk'],
        ],
      ],
    ],
    ['level_edit_category', [['attitudinal/attitudinal-category/edit/(%(cat_pk)s', ['cat_pk']]]],
    ['level_search', [['search/level-search/', []]]],
    ['level_stage_officialsection_search', [['search/level-stage-officialsection-search/', []]]],
    ['level_stage_section_search', [['search/level-stage-section-search/', []]]],
    ['levelgroup_allocate', [['levelgroup/allocate/', []]]],
    ['levelgroup_deallocate', [['levelgroup/deallocate/', []]]],
    [
      'levelgroup_refresh_assignation_form',
      [['levelgroup/refresh-assignation-form/%(action)s/', ['action']]],
    ],
    ['levelstage_search', [['search/levelstage-search/', []]]],
    ['lock_subcourse', [['api/cycle/cycles/%(courserel_pk)s/subcourses/lock/', ['courserel_pk']]]],
    ['login', [['user/login/', []]]],
    ['logout', [['user/logout/', []]]],
    ['meeting_create', [['meetings/meetings/create/', []]]],
    ['meeting_delete', [['meetings/meetings/delete/%(meeting_pk)s/', ['meeting_pk']]]],
    ['meeting_update', [['meetings/meetings/update/', []]]],
    ['mobile_register_device', [['api/register-device/', []]]],
    ['mobile_unregister_device', [['api/unregister-device/', []]]],
    ['mobile_user_profile', [['api/user-profile/', []]]],
    ['mobile_user_profile_detail', [['api/user-profile/%(pk)s/', ['pk']]]],
    ['my_section_search', [['search/my-section-search/', []]]],
    ['my_structure_search', [['search/my-structure-search/', []]]],
    ['notifications_add', [['notifications/add/', []]]],
    ['notifications_dashboard', [['dashboard/notifications/', []]]],
    [
      'notifications_get_details',
      [['notifications/%(notification_pk)s/get-details/', ['notification_pk']]],
    ],
    [
      'notifications_read',
      [['notifications/%(notification_pk)s[0-9]+)/read/', ['notification_pk']]],
    ],
    ['notifications_read_all', [['notifications/read/all', []]]],
    ['notifications_view_all', [['notifications/view-all/', []]]],
    [
      'notificationuserassignation-count-unwatched',
      [
        ['api/v3/notification-user-assignations/count-unwatched.%(format)s', ['format']],
        ['api/v3/notification-user-assignations/count-unwatched/', []],
        ['api/v2/notifications-users/count-unwatched.%(format)s', ['format']],
        ['api/v2/notifications-users/count-unwatched/', []],
        ['api/v2/notifications-users/count-unwatched.%(format)s', ['format']],
        ['api/v2/notifications-users/count-unwatched/', []],
      ],
    ],
    [
      'notificationuserassignation-detail',
      [
        ['api/v3/notification-user-assignations/%(pk)s.%(format)s', ['pk', 'format']],
        ['api/v3/notification-user-assignations/%(pk)s/', ['pk']],
        ['api/v2/notifications-users/%(pk)s.%(format)s', ['pk', 'format']],
        ['api/v2/notifications-users/%(pk)s/', ['pk']],
        ['api/v2/notifications-users/%(pk)s.%(format)s', ['pk', 'format']],
        ['api/v2/notifications-users/%(pk)s/', ['pk']],
        ['api/notifications/%(pk)s.%(format)s', ['pk', 'format']],
        ['api/notifications/%(pk)s/', ['pk']],
      ],
    ],
    [
      'notificationuserassignation-list',
      [
        ['api/v3/notification-user-assignations.%(format)s', ['format']],
        ['api/v3/notification-user-assignations/', []],
        ['api/v2/notifications-users.%(format)s', ['format']],
        ['api/v2/notifications-users/', []],
        ['api/v2/notifications-users.%(format)s', ['format']],
        ['api/v2/notifications-users/', []],
        ['api/notifications/.%(format)s', ['format']],
        ['api/notifications/', []],
      ],
    ],
    [
      'notificationuserassignation-watch-all',
      [
        ['api/v3/notification-user-assignations/watch-all.%(format)s', ['format']],
        ['api/v3/notification-user-assignations/watch-all/', []],
        ['api/v2/notifications-users/watch-all.%(format)s', ['format']],
        ['api/v2/notifications-users/watch-all/', []],
        ['api/v2/notifications-users/watch-all.%(format)s', ['format']],
        ['api/v2/notifications-users/watch-all/', []],
      ],
    ],
    ['notify_recording', [['meetings/meetings/notify-recording/', []]]],
    ['oauth2_provider:authorize', [['o/authorize/', []]]],
    ['oauth2_provider:authorized-token-delete', [['o/authorized_tokens/%(pk)s/delete/', ['pk']]]],
    ['oauth2_provider:authorized-token-list', [['o/authorized_tokens/', []]]],
    ['oauth2_provider:delete', [['o/applications/%(pk)s/delete/', ['pk']]]],
    ['oauth2_provider:detail', [['o/applications/%(pk)s/', ['pk']]]],
    ['oauth2_provider:introspect', [['o/introspect/', []]]],
    ['oauth2_provider:jwks-info', [['o/.well-known/jwks.json', []]]],
    ['oauth2_provider:list', [['o/applications/', []]]],
    ['oauth2_provider:oidc-connect-discovery-info', [['o/.well-known/openid-configuration/', []]]],
    ['oauth2_provider:register', [['o/applications/register/', []]]],
    ['oauth2_provider:revoke-token', [['o/revoke_token/', []]]],
    ['oauth2_provider:token', [['o/token/', []]]],
    ['oauth2_provider:update', [['o/applications/%(pk)s/update/', ['pk']]]],
    ['oauth2_provider:user-info', [['o/userinfo/', []]]],
    ['odoo-client-edition', [['odoo/%(student_id)s/client-edition/', ['student_id']]]],
    ['odoo-registration', [['odoo/%(student_id)s/registration/', ['student_id']]]],
    ['odoo-search-clients', [['odoo/search/clients/', []]]],
    ['odoo-tutor-invoice', [['odoo/ajax/tutor-invoice/', []]]],
    [
      'official_section_add_supervisor',
      [['structure/official-section/%(section_pk)s/add-supervisor/', ['section_pk']]],
    ],
    [
      'official_section_assign',
      [
        [
          'api/cycle/level/%(level_pk)s/official-sections/%(official_section_pk)s/assign/',
          ['level_pk', 'official_section_pk'],
        ],
      ],
    ],
    [
      'official_section_change_name',
      [['structure/official-section/%(section_pk)s/change-name/', ['section_pk']]],
    ],
    [
      'official_section_remove_supervisor',
      [
        [
          'structure/official-section/%(section_pk)s/remove-supervisor/%(supervisor_pk)s/',
          ['section_pk', 'supervisor_pk'],
        ],
      ],
    ],
    [
      'official_section_students',
      [['api/cycle/official-sections/%(official_section_pk)s/students/', ['official_section_pk']]],
    ],
    [
      'official_section_unassign',
      [['api/cycle/level/%(level_pk)s/official-sections/unassign/', ['level_pk']]],
    ],
    ['omni_search', [['search/omni-search/', []]]],
    ['omni_structure_search', [['search/omni-structure-search/', []]]],
    [
      'planification_report',
      [
        [
          'cycle/section/%(section_pk)s/report-planification/%(unit_name)s/',
          ['section_pk', 'unit_name'],
        ],
      ],
    ],
    ['play_meeting', [['meetings/meetings/play/%(meeting_pk)s/', ['meeting_pk']]]],
    [
      'pre_register_student',
      [['api/userprofiles/students/%(student_pk)s/pre_register/', ['student_pk']]],
    ],
    ['promote_student', [['cycle/promote-student/%(student_pk)s/', ['student_pk']]]],
    [
      'publicapi_levels-list',
      [
        ['public-api/v1/levels.%(format)s', ['format']],
        ['public-api/v1/levels/', []],
      ],
    ],
    [
      'publicapi_ping`-list',
      [
        ['public-api/v1/ping.%(format)s', ['format']],
        ['public-api/v1/ping/', []],
      ],
    ],
    [
      'publicapi_students_ref-change-status',
      [
        [
          'public-api/v1/userprofiles/students/code-lookup/%(code)s/change-status.%(format)s',
          ['code', 'format'],
        ],
        ['public-api/v1/userprofiles/students/code-lookup/%(code)s/change-status/', ['code']],
      ],
    ],
    [
      'publicapi_students_ref-check-status',
      [
        [
          'public-api/v1/userprofiles/students/code-lookup/%(code)s/check-status.%(format)s',
          ['code', 'format'],
        ],
        ['public-api/v1/userprofiles/students/code-lookup/%(code)s/check-status/', ['code']],
      ],
    ],
    [
      'publicapi_students_ref-detail',
      [
        ['public-api/v1/userprofiles/students/code-lookup/%(code)s.%(format)s', ['code', 'format']],
        ['public-api/v1/userprofiles/students/code-lookup/%(code)s/', ['code']],
      ],
    ],
    [
      'publicapi_students_ref-lock',
      [
        [
          'public-api/v1/userprofiles/students/code-lookup/%(code)s/lock.%(format)s',
          ['code', 'format'],
        ],
        ['public-api/v1/userprofiles/students/code-lookup/%(code)s/lock/', ['code']],
      ],
    ],
    [
      'publicapi_students_ref-unlock',
      [
        [
          'public-api/v1/userprofiles/students/code-lookup/%(code)s/unlock.%(format)s',
          ['code', 'format'],
        ],
        ['public-api/v1/userprofiles/students/code-lookup/%(code)s/unlock/', ['code']],
      ],
    ],
    [
      'publicapi_students`-change-status',
      [
        ['public-api/v1/userprofiles/students/change-status.%(format)s', ['format']],
        ['public-api/v1/userprofiles/students/change-status/', []],
      ],
    ],
    [
      'publicapi_students`-check-locked',
      [
        ['public-api/v1/userprofiles/students/check-locked.%(format)s', ['format']],
        ['public-api/v1/userprofiles/students/check-locked/', []],
      ],
    ],
    [
      'publicapi_students`-detail',
      [
        ['public-api/v1/userprofiles/students/%(pk)s.%(format)s', ['pk', 'format']],
        ['public-api/v1/userprofiles/students/%(pk)s/', ['pk']],
      ],
    ],
    [
      'publicapi_students`-list',
      [
        ['public-api/v1/userprofiles/students.%(format)s', ['format']],
        ['public-api/v1/userprofiles/students/', []],
      ],
    ],
    [
      'publicapi_students`-lock',
      [
        ['public-api/v1/userprofiles/students/lock.%(format)s', ['format']],
        ['public-api/v1/userprofiles/students/lock/', []],
      ],
    ],
    [
      'publicapi_students`-unlock',
      [
        ['public-api/v1/userprofiles/students/unlock.%(format)s', ['format']],
        ['public-api/v1/userprofiles/students/unlock/', []],
      ],
    ],
    [
      'publicapi_teachers-detail',
      [
        ['public-api/v1/userprofiles/teachers/%(code)s.%(format)s', ['code', 'format']],
        ['public-api/v1/userprofiles/teachers/%(code)s/', ['code']],
      ],
    ],
    [
      'publicapi_teachers-list',
      [
        ['public-api/v1/userprofiles/teachers.%(format)s', ['format']],
        ['public-api/v1/userprofiles/teachers/', []],
      ],
    ],
    [
      'publicapi_teachers_profiles-list',
      [
        ['public-api/v1/userprofiles/teacher-profiles.%(format)s', ['format']],
        ['public-api/v1/userprofiles/teacher-profiles/', []],
      ],
    ],
    [
      'publicapi_teachers_profiles-sections',
      [
        [
          'public-api/v1/userprofiles/teacher-profiles/%(pk)s/sections.%(format)s',
          ['pk', 'format'],
        ],
        ['public-api/v1/userprofiles/teacher-profiles/%(pk)s/sections/', ['pk']],
      ],
    ],
    [
      'publicapi_tutors_ref-detail',
      [
        ['public-api/v1/userprofiles/tutors/ref-lookup/%(ref)s.%(format)s', ['ref', 'format']],
        ['public-api/v1/userprofiles/tutors/ref-lookup/%(ref)s/', ['ref']],
      ],
    ],
    [
      'publicapi_tutors`-detail',
      [
        ['public-api/v1/userprofiles/tutors/%(pk)s.%(format)s', ['pk', 'format']],
        ['public-api/v1/userprofiles/tutors/%(pk)s/', ['pk']],
      ],
    ],
    [
      'publicapi_tutors`-list',
      [
        ['public-api/v1/userprofiles/tutors.%(format)s', ['format']],
        ['public-api/v1/userprofiles/tutors/', []],
      ],
    ],
    ['quick_report', [['reports/quick-report/', []]]],
    ['quick_reports', [['dashboard/super-admin/quick-reports/', []]]],
    [
      'redactor_upload_file',
      [
        ['redactor/upload/file/%(upload_to)s', ['upload_to']],
        ['redactor/upload/file/%(upload_to)s', ['upload_to']],
      ],
    ],
    [
      'redactor_upload_image',
      [
        ['redactor/upload/image/%(upload_to)s', ['upload_to']],
        ['redactor/upload/image/%(upload_to)s', ['upload_to']],
      ],
    ],
    [
      'redistribute_level_dosification_to_sections',
      [['structure/level/%(level_id)s/redistribute-dosification-to-sections/', ['level_id']]],
    ],
    [
      'redistribute_level_dosification_to_sections_extra_curricular',
      [
        [
          'cycle/level/%(extra_curricular_id)s/redistribute-dosification-to-sections/',
          ['extra_curricular_id'],
        ],
      ],
    ],
    ['register_staff', [['registration/register-staff/', []]]],
    ['register_student', [['registration/register-student/', []]]],
    ['register_teacher', [['registration/register-teacher/', []]]],
    ['register_tutor', [['registration/register-tutor/', []]]],
    ['registration_backend', [['registration-backend/', []]]],
    ['registration_backend_register_staff', [['registration-backend/register-staff/', []]]],
    ['registration_backend_register_student', [['registration-backend/register-student/', []]]],
    ['registration_backend_register_teacher', [['registration-backend/register-teacher/', []]]],
    ['registration_backend_register_tutor', [['registration-backend/register-tutor/', []]]],
    [
      'remove_medicines',
      [
        [
          'api/userprofiles/student/%(student_profile_pk)s/remove_medicines/',
          ['student_profile_pk'],
        ],
      ],
    ],
    [
      'remove_official_section',
      [['structure/official-section/%(section_pk)s/remove/', ['section_pk']]],
    ],
    ['remove_subcourse', [['api/cycle/cycles/subcourses/remove/%(subrelpk)s/', ['subrelpk']]]],
    ['remove_zoom_user_data', [['meetings/remove-zoom-user-data/', []]]],
    ['reports_add_to_favorites', [['reports/add-to-favorites/%(report_pk)s/', ['report_pk']]]],
    ['reports_custom_form', [['reports/custom-form/%(report_pk)s/', ['report_pk']]]],
    ['reports_dashboard', [['dashboard/reports/', []]]],
    ['reports_dashboard_admin', [['dashboard/reports/admin', []]]],
    [
      'reports_remove_from_favorites',
      [['reports/remove-from-favorites/%(report_pk)s/', ['report_pk']]],
    ],
    ['reports_render', [['reports/render/%(report_pk)s/', ['report_pk']]]],
    [
      'reports_update_structure_alfredo_colom_report_form',
      [
        [
          'reports/update-structure-alfredo-colom-report-form/%(content_type_pk)s/%(element_pk)s/',
          ['content_type_pk', 'element_pk'],
        ],
      ],
    ],
    [
      'reports_update_structure_intelligence_report_form',
      [
        [
          'reports/update-structure-intelligence-report-form/%(content_type_pk)s/%(element_pk)s/',
          ['content_type_pk', 'element_pk'],
        ],
      ],
    ],
    [
      'reports_update_user_intelligence_report_form',
      [['reports/update-user-intelligence-report-form/%(user_pk)s/', ['user_pk']]],
    ],
    ['resend_user_invitation', [['user/resend-invitation/%(user_pk)s/', ['user_pk']]]],
    ['reset_password', [['user/reset-password/%(token)s/', ['token']]]],
    ['rest_framework:login', [['api/auth/login/', []]]],
    ['rest_framework:logout', [['api/auth/logout/', []]]],
    [
      'save_attitudinal_configuration',
      [['attitudinal/save-attitudinal-configration/%(level_pk)s', ['level_pk']]],
    ],
    ['save_attitudinal_for_student', [['api/attitudinal/save-for-student/', []]]],
    [
      'save_change_cycle_configuration',
      [['structure/stage/%(stage_id)s/change-cycle-config/', ['stage_id']]],
    ],
    ['save_email_school_configuration', [['school/save-email-configuration/', []]]],
    ['save_recording_meeting', [['meetings/meetings/save-recording/', []]]],
    ['save_reports_school_configuration', [['school/save-reports-configuration/', []]]],
    ['save_school_configuration', [['school/save-configuration/', []]]],
    [
      'save_school_permissions_configuration_dashboard',
      [['school/save-configuration-permissions/', []]],
    ],
    [
      'save_stage_block_configuration',
      [['configurations/stage/%(stage_id)s/save-config-blocks/', ['stage_id']]],
    ],
    [
      'save_stage_report_configuration',
      [['structure/stage/%(stage_id)s/config-reports/', ['stage_id']]],
    ],
    [
      'save_unit_summary_configuration',
      [['structure/stage/%(stage_id)s/unit-summaries/', ['stage_id']]],
    ],
    ['save_update_school_configuration', [['school/save-update-data-configuration/', []]]],
    [
      'school-detail',
      [
        ['api/v3/schools/%(pk)s.%(format)s', ['pk', 'format']],
        ['api/v3/schools/%(pk)s/', ['pk']],
      ],
    ],
    [
      'school-get-configuration-of-the-successful-data-update-modal',
      [
        [
          'api/v3/schools/default/configuration-successful-data-update-modal.%(format)s',
          ['format'],
        ],
        ['api/v3/schools/default/configuration-successful-data-update-modal/', []],
      ],
    ],
    [
      'school-get-school-configuration',
      [
        ['api/v3/schools/default/school-configuration.%(format)s', ['format']],
        ['api/v3/schools/default/school-configuration/', []],
      ],
    ],
    [
      'school-list',
      [
        ['api/v3/schools.%(format)s', ['format']],
        ['api/v3/schools/', []],
      ],
    ],
    [
      'school-save-school-configuration',
      [
        ['api/v3/schools/default/save-school-configuration.%(format)s', ['format']],
        ['api/v3/schools/default/save-school-configuration/', []],
      ],
    ],
    ['school_configuration_dashboard', [['school/configuration/', []]]],
    ['school_configuration_dashboard_reports', [['school/configuration-reports/', []]]],
    ['school_detail', [['api/school/%(pk)s/', ['pk']]]],
    ['school_email_configuration_dashboard', [['school/configuration-email/', []]]],
    ['school_importer_dashboard', [['school/importer/', []]]],
    ['school_install', [['school/install/', []]]],
    ['school_list', [['api/school/', []]]],
    ['school_logo_settings_area', [['school/logo-settings/', []]]],
    ['school_management_type', [['odoo/synchronization/school-management-type/', []]]],
    ['school_permissions_configuration_dashboard', [['school/configuration-discipline/', []]]],
    ['school_set_theme', [['school/set-theme/', []]]],
    ['school_update_data_configuration_dashboard', [['school/configuration-update-data/', []]]],
    ['scores_reset', [['scores/reset/', []]]],
    ['scores_set', [['scores/set/', []]]],
    [
      'scoretransformation-detail',
      [
        ['api/v3/score-transformations/%(pk)s.%(format)s', ['pk', 'format']],
        ['api/v3/score-transformations/%(pk)s/', ['pk']],
      ],
    ],
    [
      'scoretransformation-list',
      [
        ['api/v3/score-transformations.%(format)s', ['format']],
        ['api/v3/score-transformations/', []],
      ],
    ],
    [
      'scoring_artifacts_checklist_create_for',
      [['scoring-artifacts/checklist/create-for/%(activity_pk)s/', ['activity_pk']]],
    ],
    [
      'scoring_artifacts_checklist_delete',
      [['scoring-artifacts/checklist/%(checklist_pk)s/delete/', ['checklist_pk']]],
    ],
    [
      'scoring_artifacts_rubric_create_for',
      [['scoring-artifacts/rubric/create-for/%(activity_pk)s/', ['activity_pk']]],
    ],
    ['search_admind_structure_to_info', [['search/admind-structure-to-info/', []]]],
    [
      'search_group',
      [
        ['api/search/search-group/', []],
        ['search/search-group/', []],
      ],
    ],
    [
      'search_level',
      [
        ['api/search/search-level/', []],
        ['search/search-level/', []],
      ],
    ],
    ['search_my_students', [['search/my-students/', []]]],
    ['search_omnisearch', [['search/omnisearch/', []]]],
    [
      'search_section',
      [
        ['api/search/search-section/', []],
        ['search/search-section/', []],
      ],
    ],
    [
      'search_stage',
      [
        ['api/search/search-stage/', []],
        ['search/search-stage/', []],
      ],
    ],
    ['search_students', [['search/students/', []]]],
    ['search_teachers', [['search/teachers/', []]]],
    ['search_tutors', [['search/tutors/', []]]],
    [
      'section-detail',
      [
        ['api/v3/sections/%(pk)s.%(format)s', ['pk', 'format']],
        ['api/v3/sections/%(pk)s/', ['pk']],
      ],
    ],
    [
      'section-list',
      [
        ['api/v3/sections.%(format)s', ['format']],
        ['api/v3/sections/', []],
      ],
    ],
    [
      'section-unit-planning',
      [
        ['api/v3/sections/%(pk)s/unit-planning.%(format)s', ['pk', 'format']],
        ['api/v3/sections/%(pk)s/unit-planning/', ['pk']],
      ],
    ],
    ['section_activities', [['activities/%(section_pk)s/activities/', ['section_pk']]]],
    ['section_allocate', [['cycle/section/allocate/', []]]],
    [
      'section_announcements_dashboard',
      [['dashboard/section/%(section_pk)s/announcements', ['section_pk']]],
    ],
    [
      'section_dashboard_activities',
      [['activities/%(section_pk)s/dashboard-activities/', ['section_pk']]],
    ],
    [
      'section_dashboard_finished_meetings',
      [['meetings/%(section_pk)s/dashboard-finished-meetings/', ['section_pk']]],
    ],
    ['section_deallocate', [['cycle/section/deallocate/', []]]],
    [
      'section_gradecells_for_teacher',
      [['api/userprofiles/teacher/%(user_pk)s/base-section-gradecells', ['user_pk']]],
    ],
    [
      'section_refresh_assignation_form',
      [['cycle/section/refresh-assignation-form/%(action)s/', ['action']]],
    ],
    ['section_search', [['search/section-search/', []]]],
    ['section_structure', [['api/cycle/section/%(pk)s', ['pk']]]],
    [
      'sections-activity-create',
      [
        ['public-api/v1/sections/%(pk)s/activities.%(format)s', ['pk', 'format']],
        ['public-api/v1/sections/%(pk)s/activities/', ['pk']],
      ],
    ],
    [
      'sections-activity-delete',
      [
        [
          'public-api/v1/sections/%(pk)s/activities/%(activity_pk)s.%(format)s',
          ['pk', 'activity_pk', 'format'],
        ],
        ['public-api/v1/sections/%(pk)s/activities/%(activity_pk)s/', ['pk', 'activity_pk']],
      ],
    ],
    [
      'sections-detail',
      [
        ['public-api/v1/sections/%(pk)s.%(format)s', ['pk', 'format']],
        ['public-api/v1/sections/%(pk)s/', ['pk']],
      ],
    ],
    [
      'sections-set-score',
      [
        [
          'public-api/v1/sections/%(pk)s/activities/%(activity_pk)s/set-score.%(format)s',
          ['pk', 'activity_pk', 'format'],
        ],
        [
          'public-api/v1/sections/%(pk)s/activities/%(activity_pk)s/set-score/',
          ['pk', 'activity_pk'],
        ],
      ],
    ],
    ['sections_units', [['api/cycle/sections/units/%(student_pk)s/', ['student_pk']]]],
    ['security_configuration_dashboard', [['dashboard/configuration/security/', []]]],
    ['send_assistance_for_day', [['assistance/send-assistance-for-day/', []]]],
    ['send_deposit_slip_dashboard', [['dashboard/send-deposit-slip/(%(bank)s/', ['bank']]]],
    [
      'send_financial_statement_notification',
      [['api/notifications/send-financial-statement-notification/', []]],
    ],
    ['send_grades_for_template_unit', [['cycle/template-unit/send-grades/', []]]],
    [
      'send_payment_reminder_notification',
      [['api/notifications/send-payment-reminder-notification/', []]],
    ],
    ['send_performance_report', [['cycle/template-unit/send_performance_report/', []]]],
    [
      'send_student_performance_report',
      [['cycle/unit/%(unit_pk)s/send-student-performance-report/', ['unit_pk']]],
    ],
    ['services_configuration_dashboard', [['dashboard/configuration/services/', []]]],
    ['set_expire_session', [['user/%(user_pk)s/set-expire-session/', ['user_pk']]]],
    ['set_student_extra_score', [['scores/set-student-extra-score/', []]]],
    ['sign_key', [['api/files/sign-key', []]]],
    ['sortable_sort', [['sortable/sort/', []]]],
    ['staff_dashboard', [['dashboard/staff/', []]]],
    ['staff_members_dashboard', [['dashboard/staff/staff-members/', []]]],
    [
      'staff_search',
      [
        ['api/v3/search/staff-search/', []],
        ['search/staff-search/', []],
      ],
    ],
    ['staffprofile', [['profile/staff/%(username)s/', ['username']]]],
    [
      'staffprofile_personal_information',
      [['profile/staff/%(username)s/staff-personal-information/basic', ['username']]],
    ],
    [
      'staffprofile_personal_information_files',
      [['profile/staff/%(username)s/staff-personal-information/files/', ['username']]],
    ],
    [
      'stage-blockings',
      [
        ['api/v3/stages/%(pk)s/blockings.%(format)s', ['pk', 'format']],
        ['api/v3/stages/%(pk)s/blockings/', ['pk']],
      ],
    ],
    [
      'stage-config-with-metadata',
      [
        ['api/v3/stages/%(pk)s/config-with-metadata.%(format)s', ['pk', 'format']],
        ['api/v3/stages/%(pk)s/config-with-metadata/', ['pk']],
      ],
    ],
    [
      'stage-configurations',
      [
        ['api/v3/stages/%(pk)s/configurations.%(format)s', ['pk', 'format']],
        ['api/v3/stages/%(pk)s/configurations/', ['pk']],
      ],
    ],
    [
      'stage-detail',
      [
        ['api/v3/stages/%(pk)s.%(format)s', ['pk', 'format']],
        ['api/v3/stages/%(pk)s/', ['pk']],
      ],
    ],
    [
      'stage-get-by-uuid',
      [
        ['api/v3/stages/uuid/%(stage_uuid)s.%(format)s', ['stage_uuid', 'format']],
        ['api/v3/stages/uuid/%(stage_uuid)s/', ['stage_uuid']],
      ],
    ],
    [
      'stage-list',
      [
        ['api/v3/stages.%(format)s', ['format']],
        ['api/v3/stages/', []],
      ],
    ],
    [
      'stage-save-stage-config',
      [
        ['api/v3/stages/%(pk)s/save-stage-configuration.%(format)s', ['pk', 'format']],
        ['api/v3/stages/%(pk)s/save-stage-configuration/', ['pk']],
      ],
    ],
    ['stage_activate_unit', [['structure/stage/%(stage_id)s/activate-unit/', ['stage_id']]]],
    ['stage_backend', [['stage-backend/%(stage_id)s/', ['stage_id']]]],
    ['stage_backend_configuration', [['stage-backend/%(stage_id)s/configuration/', ['stage_id']]]],
    [
      'stage_backend_default_score_distribution',
      [['stage-backend/%(stage_id)s/default-score-distribution/', ['stage_id']]],
    ],
    [
      'stage_backend_default_score_distribution_edit',
      [['stage-backend/%(stage_id)s/default-score-distribution/edit/', ['stage_id']]],
    ],
    [
      'stage_backend_mailing_config',
      [['stage-backend/%(stage_id)s/mailing-config/', ['stage_id']]],
    ],
    [
      'stage_backend_unit_configuration',
      [['stage-backend/%(stage_id)s/unit-coniguration/', ['stage_id']]],
    ],
    [
      'stage_block_configuration',
      [['configurations/stage/%(stage_id)s/block-configuration/', ['stage_id']]],
    ],
    ['stages_backend', [['stages-backend/', []]]],
    ['start_meeting', [['meetings/meetings/start/%(meeting_pk)s/', ['meeting_pk']]]],
    ['structure_log_items', [['structure/structure-log/', []]]],
    ['structure_search', [['search/structure-search/', []]]],
    ['student_activity_submission', [['activities/activity/student-submission/', []]]],
    ['student_calendar_dashboard', [['dashboard/student/calendar/', []]]],
    ['student_dashboard', [['dashboard/student/', []]]],
    [
      'student_dashboard_activities',
      [['activities/%(username)s/student-dashboard-activities/', ['username']]],
    ],
    ['student_grades_dashboard', [['dashboard/student/grades/', []]]],
    ['student_members_dashboard', [['dashboard/staff/student-members/', []]]],
    ['student_payload', [['api/v3/docs/students/%(student_uuid)s/payload/', ['student_uuid']]]],
    [
      'student_performance_course_report',
      [['profile/student/%(username)s/performance-course-report/', ['username']]],
    ],
    ['student_search', [['search/student-search/', []]]],
    [
      'student_section_activities_dashboard',
      [['dashboard/student/section/%(section_pk)s/activities/', ['section_pk']]],
    ],
    [
      'student_section_announcements_dashboard',
      [['dashboard/student/section/%(section_pk)s/announcements/', ['section_pk']]],
    ],
    [
      'student_section_announcements_dashboard_items',
      [['dashboard/student/section/%(section_pk)s/paginated-announcements/', ['section_pk']]],
    ],
    ['student_section_dashboard', [['dashboard/student/section/%(section_pk)s/', ['section_pk']]]],
    ['student_sections_dashboard', [['dashboard/student/sections/', []]]],
    ['student_sections_dashboard_items', [['dashboard/student/paginated-sections/', []]]],
    [
      'student_stage_configuration',
      [['stage-backend/student-stage-configuration/%(student_pk)s/', ['student_pk']]],
    ],
    ['student_week_calendar_dashboard', [['dashboard/student/week-calendar/', []]]],
    ['studentprofile', [['profile/student/%(username)s/', ['username']]]],
    [
      'studentprofile-activity-log',
      [
        ['api/v3/student-profiles/%(pk)s/activity-log.%(format)s', ['pk', 'format']],
        ['api/v3/student-profiles/%(pk)s/activity-log/', ['pk']],
      ],
    ],
    [
      'studentprofile-deducted-units',
      [
        ['api/v3/student-profiles/%(pk)s/deducted-units.%(format)s', ['pk', 'format']],
        ['api/v3/student-profiles/%(pk)s/deducted-units/', ['pk']],
      ],
    ],
    [
      'studentprofile-detail',
      [
        ['api/v3/student-profiles/%(pk)s.%(format)s', ['pk', 'format']],
        ['api/v3/student-profiles/%(pk)s/', ['pk']],
      ],
    ],
    [
      'studentprofile-event-log',
      [
        ['api/v3/student-profiles/%(pk)s/event-log.%(format)s', ['pk', 'format']],
        ['api/v3/student-profiles/%(pk)s/event-log/', ['pk']],
      ],
    ],
    [
      'studentprofile-grades',
      [
        ['api/v3/student-profiles/%(pk)s/grades.%(format)s', ['pk', 'format']],
        ['api/v3/student-profiles/%(pk)s/grades/', ['pk']],
      ],
    ],
    [
      'studentprofile-historic-activity-log',
      [
        ['api/v3/student-profiles/%(pk)s/historic-activity-log.%(format)s', ['pk', 'format']],
        ['api/v3/student-profiles/%(pk)s/historic-activity-log/', ['pk']],
      ],
    ],
    [
      'studentprofile-list',
      [
        ['api/v3/student-profiles.%(format)s', ['format']],
        ['api/v3/student-profiles/', []],
      ],
    ],
    [
      'studentprofile-section-assignations',
      [
        ['api/v3/student-profiles/%(pk)s/section-assignations.%(format)s', ['pk', 'format']],
        ['api/v3/student-profiles/%(pk)s/section-assignations/', ['pk']],
      ],
    ],
    [
      'studentprofile-sheet-headers-for-upload-in-bulk',
      [
        ['api/v3/student-profiles/sheet-headers-for-upload-in-bulk.%(format)s', ['format']],
        ['api/v3/student-profiles/sheet-headers-for-upload-in-bulk/', []],
      ],
    ],
    [
      'studentprofile-status',
      [
        ['api/v3/student-profiles/%(pk)s/status.%(format)s', ['pk', 'format']],
        ['api/v3/student-profiles/%(pk)s/status/', ['pk']],
      ],
    ],
    [
      'studentprofile-upload-students-in-bulk',
      [
        ['api/v3/student-profiles/upload-in-bulk.%(format)s', ['format']],
        ['api/v3/student-profiles/upload-in-bulk/', []],
      ],
    ],
    ['studentprofile_activities', [['profile/student/%(username)s/activities/', ['username']]]],
    ['studentprofile_add_tutor', [['profile/student/%(username)s/tutors/add/', ['username']]]],
    [
      'studentprofile_deny_scores_view',
      [['profile/student/%(username)s/deny-scores-view/', ['username']]],
    ],
    [
      'studentprofile_grade_detail',
      [
        [
          'profile/student/%(username)s/grade-detail/%(gradecell_pk)s/',
          ['username', 'gradecell_pk'],
        ],
      ],
    ],
    ['studentprofile_grades', [['profile/student/%(username)s/grades/', ['username']]]],
    [
      'studentprofile_grant_scores_view',
      [['profile/student/%(username)s/grant-scores-view/', ['username']]],
    ],
    [
      'studentprofile_personal_information',
      [['profile/student/%(username)s/student-personal-information/basic', ['username']]],
    ],
    [
      'studentprofile_personal_information_assignations',
      [['profile/student/%(username)s/student-personal-information/assignations/', ['username']]],
    ],
    [
      'studentprofile_personal_information_consultations',
      [['profile/student/%(username)s/student-personal-information/consultations/', ['username']]],
    ],
    [
      'studentprofile_personal_information_consultations_add',
      [
        [
          'profile/student/%(username)s/student-personal-information/consultations/add/',
          ['username'],
        ],
      ],
    ],
    [
      'studentprofile_personal_information_consultations_delete',
      [
        [
          'profile/student/%(username)s/student-personal-information/consultations/delete/%(consultation_pk)s',
          ['username', 'consultation_pk'],
        ],
      ],
    ],
    [
      'studentprofile_personal_information_consultations_edit',
      [
        [
          'profile/student/%(username)s/student-personal-information/consultations/edit/%(consultation_pk)s',
          ['username', 'consultation_pk'],
        ],
      ],
    ],
    [
      'studentprofile_personal_information_files',
      [['profile/student/%(username)s/student-personal-information/files/', ['username']]],
    ],
    [
      'studentprofile_personal_information_medical',
      [['profile/student/%(username)s/student-personal-information/medical/', ['username']]],
    ],
    [
      'studentprofile_personal_information_tutors',
      [['profile/student/%(username)s/student-personal-information/tutors/', ['username']]],
    ],
    [
      'studentprofile_remove_tutor',
      [['profile/student/%(username)s/tutors/remove/', ['username']]],
    ],
    [
      'studentprofile_section_unit_grade_detail',
      [
        [
          'dashboard/studentprofile-section-unit-grade-detail/%(student_pk)s/%(unit_pk)s/',
          ['student_pk', 'unit_pk'],
        ],
      ],
    ],
    ['studentprofile_track', [['profile/student/%(username)s/track/', ['username']]]],
    ['studentprofile_tutors', [['profile/student/%(username)s/tutors/', ['username']]]],
    [
      'studentprofile_update_legal_tutor',
      [
        [
          'profile/student/%(username)s/student-personal-information/update-legal-tutor/%(relation_pk)s',
          ['username', 'relation_pk'],
        ],
      ],
    ],
    ['studentprofiles_block', [['profile/block-students/', []]]],
    ['studentprofiles_pre_register', [['profile/pre-register-students/', []]]],
    [
      'students_without_official_section',
      [
        [
          'api/cycle/official-sections/%(level_pk)s/students-without-official-section/',
          ['level_pk'],
        ],
      ],
    ],
    [
      'studentsectionassignation-detail',
      [
        ['api/v3/student-section-assignations/%(pk)s.%(format)s', ['pk', 'format']],
        ['api/v3/student-section-assignations/%(pk)s/', ['pk']],
      ],
    ],
    [
      'studentsectionassignation-extra-scores',
      [
        ['api/v3/student-section-assignations/%(pk)s/extra-scores.%(format)s', ['pk', 'format']],
        ['api/v3/student-section-assignations/%(pk)s/extra-scores/', ['pk']],
      ],
    ],
    [
      'studentsectionassignation-list',
      [
        ['api/v3/student-section-assignations.%(format)s', ['format']],
        ['api/v3/student-section-assignations/', []],
      ],
    ],
    [
      'studentsectionassignation-scores',
      [
        ['api/v3/student-section-assignations/%(pk)s/scores.%(format)s', ['pk', 'format']],
        ['api/v3/student-section-assignations/%(pk)s/scores/', ['pk']],
      ],
    ],
    [
      'studentsectionassignation-unit-activities',
      [
        ['api/v3/student-section-assignations/%(pk)s/unit-activities.%(format)s', ['pk', 'format']],
        ['api/v3/student-section-assignations/%(pk)s/unit-activities/', ['pk']],
      ],
    ],
    [
      'studentsectionassignation-unit-divisions',
      [
        ['api/v3/student-section-assignations/%(pk)s/unit-divisions.%(format)s', ['pk', 'format']],
        ['api/v3/student-section-assignations/%(pk)s/unit-divisions/', ['pk']],
      ],
    ],
    [
      'studentsectionassignation-unit-divisions-scores',
      [
        [
          'api/v3/student-section-assignations/%(pk)s/unit-divisions-scores.%(format)s',
          ['pk', 'format'],
        ],
        ['api/v3/student-section-assignations/%(pk)s/unit-divisions-scores/', ['pk']],
      ],
    ],
    ['subcourses', [['api/cycle/cycles/%(course_pk)s/subcourses/', ['course_pk']]]],
    [
      'super_bulk_block_dashboard',
      [
        ['dashboard/super-admin/bulk-pre-register/', []],
        ['dashboard/super-admin/bulk-block/', []],
      ],
    ],
    ['super_dashboard', [['dashboard/super-admin/', []]]],
    [
      'super_generate_passwords_in_bulk_dashboard',
      [['dashboard/super-admin/generate-passwords-in-bulk/', []]],
    ],
    ['synchronization_account_statements', [['odoo/synchronization/account-statements/', []]]],
    ['teacher_assistance_dashboard', [['dashboard/teacher/assistance/', []]]],
    ['teacher_calendar_dashboard', [['dashboard/teacher/calendar/', []]]],
    [
      'teacher_cycle_planning_dashboard',
      [['dashboard/teacher/section/%(section_pk)s/cycle-planning/', ['section_pk']]],
    ],
    ['teacher_dashboard', [['dashboard/teacher/', []]]],
    [
      'teacher_dashboard_activities',
      [['activities/%(username)s/teacher-dashboard-activities/', ['username']]],
    ],
    ['teacher_discipline_dashboard', [['dashboard/teacher/discipline/', []]]],
    ['teacher_discipline_dashboard_items', [['dashboard/teacher/paginated-discipline/', []]]],
    [
      'teacher_grade_activity_dashboard',
      [['dashboard/teacher/grade-activity/%(activity_pk)s/', ['activity_pk']]],
    ],
    [
      'teacher_grade_activity_dashboard_items',
      [['dashboard/teacher/paginated-grade-activity/%(activity_pk)s/', ['activity_pk']]],
    ],
    [
      'teacher_grade_activity_submission_dashboard',
      [
        [
          'dashboard/teacher/grade-activity/%(activity_pk)s/submission/%(student_pk)s/',
          ['activity_pk', 'student_pk'],
        ],
      ],
    ],
    [
      'teacher_no_ec_section_structure',
      [['api/userprofiles/teacher/%(pk)s/all-units/section-structure', ['pk']]],
    ],
    [
      'teacher_search',
      [
        ['api/v3/search/teacher-search/', []],
        ['search/teacher-search/', []],
      ],
    ],
    [
      'teacher_section_activities_dashboard',
      [['dashboard/teacher/section/%(section_pk)s/activities/', ['section_pk']]],
    ],
    [
      'teacher_section_announcements_dashboard',
      [['dashboard/teacher/section/%(section_pk)s/announcements/', ['section_pk']]],
    ],
    [
      'teacher_section_announcements_dashboard_items',
      [['dashboard/teacher/section/%(section_pk)s/paginated-announcements/', ['section_pk']]],
    ],
    ['teacher_section_dashboard', [['dashboard/teacher/section/%(section_pk)s/', ['section_pk']]]],
    [
      'teacher_section_meetings_dashboard',
      [['dashboard/teacher/section/%(section_pk)s/meetings/', ['section_pk']]],
    ],
    [
      'teacher_section_score_distribution_dashboard',
      [['dashboard/teacher/section/%(section_pk)s/score-distribution/', ['section_pk']]],
    ],
    ['teacher_section_structure', [['api/userprofiles/teacher/%(pk)s/section-structure', ['pk']]]],
    [
      'teacher_section_student_grading_dashboard',
      [['dashboard/teacher/section/%(section_pk)s/student-grading/', ['section_pk']]],
    ],
    [
      'teacher_section_student_grading_dashboard_items',
      [['dashboard/teacher/section/%(section_pk)s/paginated-student-grading/', ['section_pk']]],
    ],
    [
      'teacher_section_students_dashboard',
      [['dashboard/teacher/section/%(section_pk)s/students/', ['section_pk']]],
    ],
    [
      'teacher_section_students_dashboard_items',
      [['dashboard/teacher/section/%(section_pk)s/paginated-students/', ['section_pk']]],
    ],
    ['teacher_sections_dashboard', [['dashboard/teacher/sections/', []]]],
    ['teacher_sections_dashboard_items', [['dashboard/teacher/paginated-sections/', []]]],
    [
      'teacher_unit_planning_dashboard',
      [['dashboard/teacher/section/%(section_pk)s/unit-planning/', ['section_pk']]],
    ],
    ['teacher_week_calendar_dashboard', [['dashboard/teacher/week-calendar/', []]]],
    [
      'teacher_week_planning_dashboard',
      [['dashboard/teacher/section/%(section_pk)s/week-planning/', ['section_pk']]],
    ],
    ['teacherprofile', [['profile/teacher/%(username)s/', ['username']]]],
    [
      'teacherprofile-activity-log',
      [
        ['api/v3/teacher-profiles/%(pk)s/activity-log.%(format)s', ['pk', 'format']],
        ['api/v3/teacher-profiles/%(pk)s/activity-log/', ['pk']],
      ],
    ],
    [
      'teacherprofile-detail',
      [
        ['api/v3/teacher-profiles/%(pk)s.%(format)s', ['pk', 'format']],
        ['api/v3/teacher-profiles/%(pk)s/', ['pk']],
      ],
    ],
    [
      'teacherprofile-event-log',
      [
        ['api/v3/teacher-profiles/%(pk)s/event-log.%(format)s', ['pk', 'format']],
        ['api/v3/teacher-profiles/%(pk)s/event-log/', ['pk']],
      ],
    ],
    [
      'teacherprofile-historic-activity-log',
      [
        ['api/v3/teacher-profiles/%(pk)s/historic-activity-log.%(format)s', ['pk', 'format']],
        ['api/v3/teacher-profiles/%(pk)s/historic-activity-log/', ['pk']],
      ],
    ],
    [
      'teacherprofile-list',
      [
        ['api/v3/teacher-profiles.%(format)s', ['format']],
        ['api/v3/teacher-profiles/', []],
      ],
    ],
    [
      'teacherprofile_personal_information',
      [['profile/teacher/%(username)s/teacher-personal-information/basic', ['username']]],
    ],
    [
      'teacherprofile_personal_information_files',
      [['profile/teacher/%(username)s/teacher-personal-information/files/', ['username']]],
    ],
    [
      'teaching_plan_remove_file',
      [['cycle/teaching-plan/%(teaching_plan_pk)s/remove-file/', ['teaching_plan_pk']]],
    ],
    [
      'template_units_save_for_level',
      [['cycle/template-units/save-for-level/%(level_pk)s/', ['level_pk']]],
    ],
    [
      'template_units_save_for_stage',
      [['cycle/template-units/save-for-stage/%(stage_pk)s/', ['stage_pk']]],
    ],
    [
      'toggle_allow_student_announcements',
      [['cycle/section/%(section_pk)s/toggle-allow-student-announcements', ['section_pk']]],
    ],
    [
      'toggle_medical_information',
      [
        [
          'api/userprofiles/student/%(student_profile_pk)s/%(medical_key)s/toggle_medical_information/',
          ['student_profile_pk', 'medical_key'],
        ],
      ],
    ],
    [
      'toggle_medicines',
      [
        [
          'api/userprofiles/student/%(student_profile_pk)s/%(medicine_pk)s/toggle-medicine/',
          ['student_profile_pk', 'medicine_pk'],
        ],
      ],
    ],
    ['tutor_accounting_dashboard', [['dashboard/tutor/accounting/', []]]],
    ['tutor_accounting_download', [['dashboard/tutor/accounting/download/', []]]],
    ['tutor_dashboard', [['dashboard/tutor/', []]]],
    ['tutor_search', [['search/tutor-search/', []]]],
    ['tutor_student_search', [['search/tutor-student-search/', []]]],
    ['tutor_student_shop', [['dashboard/tutor/shop/%(student_pk)s', ['student_pk']]]],
    ['tutorprofile', [['profile/tutor/%(username)s/', ['username']]]],
    [
      'tutorprofile-account-statement',
      [
        ['api/v3/tutor-profiles/%(pk)s/account-statement.%(format)s', ['pk', 'format']],
        ['api/v3/tutor-profiles/%(pk)s/account-statement/', ['pk']],
      ],
    ],
    [
      'tutorprofile-account-statement-permissions',
      [
        ['api/v3/tutor-profiles/%(pk)s/account-statement-permissions.%(format)s', ['pk', 'format']],
        ['api/v3/tutor-profiles/%(pk)s/account-statement-permissions/', ['pk']],
      ],
    ],
    [
      'tutorprofile-children',
      [
        ['api/v3/tutor-profiles/%(pk)s/children.%(format)s', ['pk', 'format']],
        ['api/v3/tutor-profiles/%(pk)s/children/', ['pk']],
      ],
    ],
    [
      'tutorprofile-detail',
      [
        ['api/v3/tutor-profiles/%(pk)s.%(format)s', ['pk', 'format']],
        ['api/v3/tutor-profiles/%(pk)s/', ['pk']],
        ['api/v2/tutors/%(pk)s.%(format)s', ['pk', 'format']],
        ['api/v2/tutors/%(pk)s/', ['pk']],
        ['api/v2/tutors/%(pk)s.%(format)s', ['pk', 'format']],
        ['api/v2/tutors/%(pk)s/', ['pk']],
      ],
    ],
    [
      'tutorprofile-list',
      [
        ['api/v3/tutor-profiles.%(format)s', ['format']],
        ['api/v3/tutor-profiles/', []],
        ['api/v2/tutors.%(format)s', ['format']],
        ['api/v2/tutors/', []],
        ['api/v2/tutors.%(format)s', ['format']],
        ['api/v2/tutors/', []],
      ],
    ],
    [
      'tutorprofile-require-tutors-update-info',
      [
        ['api/v3/tutor-profiles/require-tutors-update-info.%(format)s', ['format']],
        ['api/v3/tutor-profiles/require-tutors-update-info/', []],
      ],
    ],
    [
      'tutorprofile-students',
      [
        ['api/v2/tutors/%(pk)s/students.%(format)s', ['pk', 'format']],
        ['api/v2/tutors/%(pk)s/students/', ['pk']],
        ['api/v2/tutors/%(pk)s/students.%(format)s', ['pk', 'format']],
        ['api/v2/tutors/%(pk)s/students/', ['pk']],
      ],
    ],
    ['tutorprofile_remove_student', [['profile/tutor/%(username)s/remove/', ['username']]]],
    ['unit_full_edition', [['unit/%(gradecell_pk)s/full-edition/', ['gradecell_pk']]]],
    ['unit_log_items', [['cycle/unit-log/', []]]],
    ['unit_require_review', [['cycle/unit/%(unit_pk)s/require-review/', ['unit_pk']]]],
    ['unit_review', [['cycle/unit/%(unit_pk)s/review/', ['unit_pk']]]],
    ['unit_section_performance', [['cycle/unit/%(unit_pk)s/section-performance/', ['unit_pk']]]],
    [
      'unit_summary_stage_backend_configuration',
      [['stage-backend/%(stage_id)s/configuration/unit-summary/', ['stage_id']]],
    ],
    [
      'units-detail',
      [
        ['public-api/v1/units/%(pk)s.%(format)s', ['pk', 'format']],
        ['public-api/v1/units/%(pk)s/', ['pk']],
      ],
    ],
    ['update_information', [['dashboard/tutor/update-information/', []]]],
    ['update_information_step', [['dashboard/tutor/update-information/%(step)s/', ['step']]]],
    ['update_students_code', [['api/userprofiles/students/update-codes/', []]]],
    [
      'update_teaching_plan',
      [['cycle/teaching-plan/%(teaching_plan_pk)s/update/', ['teaching_plan_pk']]],
    ],
    [
      'upload_activities_excel',
      [['cycle/section/%(section_pk)s/upload-activities-excel/', ['section_pk']]],
    ],
    ['upload_file_activity', [['activities/activity/upload-file-activity/', []]]],
    ['user_availability_check', [['registration/user-availability-check/', []]]],
    ['user_disable', [['user/%(username)s/disable/', ['username']]]],
    ['usermemory_remember', [['usermemory/remember/', []]]],
    ['userprofile', [['profile/%(username)s/', ['username']]]],
    ['userprofile_add_allergy', [['profile/%(username)s/medical-info/add-allergy/', ['username']]]],
    [
      'userprofile_add_covid_vaccine',
      [['profile/%(username)s/medical-info/add-covid-vaccine/', ['username']]],
    ],
    [
      'userprofile_add_information_about_covid',
      [['profile/%(username)s/medical-info/add-information-about-covid/', ['username']]],
    ],
    [
      'userprofile_add_medical_condition',
      [['profile/%(username)s/medical-info/add-medical-condition/', ['username']]],
    ],
    [
      'userprofile_add_medical_contraindication',
      [['profile/%(username)s/medical-info/add-medical-contraindication/', ['username']]],
    ],
    [
      'userprofile_add_medical_prohibition',
      [['profile/%(username)s/medical-info/add-medical-prohibition/', ['username']]],
    ],
    [
      'userprofile_add_medication',
      [['profile/%(username)s/medical-info/add-medication/', ['username']]],
    ],
    [
      'userprofile_add_surgical_procedure',
      [['profile/%(username)s/medical-info/add-surgical-procedure/', ['username']]],
    ],
    [
      'userprofile_medical_info_update',
      [['profile/%(username)s/medical-info/update/', ['username']]],
    ],
    [
      'userprofile_remove_allergy',
      [
        [
          'profile/%(username)s/medical-info/remove-allergy/%(allergy_pk)s/',
          ['username', 'allergy_pk'],
        ],
      ],
    ],
    [
      'userprofile_remove_covid_vaccine',
      [
        [
          'profile/%(username)s/medical-info/remove-covid-vaccine/%(covid_vaccine_pk)s/',
          ['username', 'covid_vaccine_pk'],
        ],
      ],
    ],
    [
      'userprofile_remove_information_about_covid',
      [
        [
          'profile/%(username)s/medical-info/remove-information-about-covid/%(information_about_covid_pk)s/',
          ['username', 'information_about_covid_pk'],
        ],
      ],
    ],
    [
      'userprofile_remove_medical_condition',
      [
        [
          'profile/%(username)s/medical-info/remove-medical-condition/%(medical_condition_pk)s/',
          ['username', 'medical_condition_pk'],
        ],
      ],
    ],
    [
      'userprofile_remove_medical_contraindication',
      [
        [
          'profile/%(username)s/medical-info/remove-medical-contraindication/%(medical_contraindication_pk)s/',
          ['username', 'medical_contraindication_pk'],
        ],
      ],
    ],
    [
      'userprofile_remove_medical_prohibition',
      [
        [
          'profile/%(username)s/medical-info/remove-medical-prohibition/(%(medical_prohibition_pk)s/',
          ['username', 'medical_prohibition_pk'],
        ],
      ],
    ],
    [
      'userprofile_remove_medication',
      [
        [
          'profile/%(username)s/medical-info/remove-medication/%(medication_pk)s/',
          ['username', 'medication_pk'],
        ],
      ],
    ],
    [
      'userprofile_remove_surgical_procedure',
      [
        [
          'profile/%(username)s/medical-info/remove-surgical-procedure/%(surgical_procedure_pk)s/',
          ['username', 'surgical_procedure_pk'],
        ],
      ],
    ],
    ['userprofiles_generate_passwords', [['profile/generate-passwords/', []]]],
    [
      'users-check-exists',
      [
        ['public-api/v1/users/check-exists.%(format)s', ['format']],
        ['public-api/v1/users/check-exists/', []],
      ],
    ],
    [
      'users-list',
      [
        ['public-api/v1/users.%(format)s', ['format']],
        ['public-api/v1/users/', []],
      ],
    ],
    ['users_first_configuration', [['user/first-configuration/%(token)s/', ['token']]]],
    ['verify_login', [['cycle/verify-login/', []]]],
    ['weekly_calendar_dashboard', [['dashboard/weekly-calendar/', []]]],
  ],
  prefix: '/',
};
