import React, { memo } from 'react';
import { Trans } from 'react-i18next';
import { MinimalStudent } from '../../../adapters/types';
import Student from '../Student';
import styles from './StudentList.module.scss';

interface Props {
  // onSelectionChange: (selectedStudents: number[]) => void;
  onStudentCheck: (studentId: number, checked: boolean) => void;
  groupName: string;
  students: MinimalStudent[];
  selectedStudentIds: number[];
  onSelecAll: () => void;
  onUnselectAll: () => void;
}

const StudentList = memo(
  ({
    groupName,
    students,
    onStudentCheck,
    selectedStudentIds,
    onSelecAll,
    onUnselectAll,
  }: Props) => {
    const checkAllInputValue = selectedStudentIds.length === students.length;

    const toggleAll = () => {
      if (checkAllInputValue) {
        onUnselectAll();
      } else {
        onSelecAll();
      }
    };

    return (
      <div className={styles['container']}>
        <p className={styles['title']}>
          <Trans i18nKey="SidePanel.editStudentListFrom" /> {groupName}
        </p>
        <div className={styles['select-all']}>
          <input type="checkbox" checked={checkAllInputValue} onChange={toggleAll} />
          <label onClick={toggleAll}>
            <Trans i18nKey="SidePanel.allStudents" />
          </label>
        </div>
        {students.map((student) => (
          <Student
            key={student.id}
            name={student.user.name}
            image={student.user.tinyProfilePicture}
            checked={selectedStudentIds.includes(student.id)}
            onChange={(checked) => {
              onStudentCheck(student.id, checked);
            }}
          />
        ))}
      </div>
    );
  },
);

StudentList.displayName = 'StudentList';

export default StudentList;
