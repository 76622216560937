export const API_BASE_URL = document.location.origin;
export const IS_API_IN_DEV_MODE = false;

export const SchoolConfigTypes = {
  GENERAL: 'general',
  TUTOR: 'tutor',
  LEGAL_TUTOR: 'legal_tutor',
};

export const StageConfigTypes = {
  GENERAL: 'general',
  MAILING: 'mailing',
};

export const PREFLIGHT_MODE = 'preflight';

export enum EdooImporterSteps {
  DataType = 0,
  Cycle = 1,
  UploadFile = 2,
  FileValidation = 3,
}

export enum EdooImporterErrorTypes {
  InvalidRows = 'invalid_rows',
  // This validation is made on backend now...
  // InvalidTutorRows = "invalid_tutor_rows",
  InvalidHeaders = 'invalid_headers',
}

// NOTE: Check Importer CSV Template if you want to update this indexes
export const EDOO_IMPORTER_REQUIRED_STUDENTS_COLUMNS_TYPE = 'student';
export const EDOO_IMPORTER_REQUIRED_FATHER_COLUMNS_TYPE = 'father';
export const EDOO_IMPORTER_REQUIRED_MOTHER_COLUMNS_TYPE = 'mother';
