import { StudentProfiles } from '../api';

import {
  AsyncFunction,
  SheetHeaderBulkUploadResponse,
  StudentsBulkUploadRequest,
  StudentsBulkUploadResponse,
} from './types';

export const fetchSheetHeadersForBulkUpload: AsyncFunction<
  string,
  SheetHeaderBulkUploadResponse
> = async (jwtToken: string) => {
  return await StudentProfiles.custom.getSheetHeadersForBulkUpload({ token: jwtToken });
};

export const uploadStudentsInBulk: AsyncFunction<
  StudentsBulkUploadRequest,
  StudentsBulkUploadResponse
> = async (request: StudentsBulkUploadRequest) => {
  const data = {
    ...request,
    jwtToken: undefined,
  };
  const { jwtToken } = request;
  return await StudentProfiles.custom.uploadInBulk({ token: jwtToken, data });
};
