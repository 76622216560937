import { OPTION_TYPE, Typeahead } from '@nahualventure/paper-ui';
import { searchOmni, searchStructure } from '../../../adapters/searches';
import React, { useCallback, useContext } from 'react';
import { ContextType, OmniSearch, StructureSearch } from '../../../data/types';
import { GlobalEdooContext } from '../../../data/globalEdooContext';
import { UserSearchRenderItem } from '../../UserSearchEngine';

interface SearcherProps {
  jwtToken: string;
  name: string;
}

export type GroupTypes = 'structure' | 'person' | 'school' | 'staff';

export interface SearcherResponse {
  value: number;
  contentType: number;
  label: string;
  subLabel: string;
  groupType: GroupTypes;
  internalType?: string;
}

const getStructureSearch = async ({
  jwtToken,
  name,
}: SearcherProps): Promise<SearcherResponse[]> => {
  const response = await searchStructure(jwtToken, name);
  return response
    .filter(({ pkey }) => pkey !== undefined)
    .map(
      ({
        pkey = 0,
        contentType,
        text,
        subtitle = '',
        isSchool = false,
        isSection,
        isOfficialSection,
      }: StructureSearch) => ({
        value: pkey,
        contentType,
        label: text,
        subLabel: subtitle,
        groupType: isSchool ? 'school' : 'structure',
        internalType: isSection ? 'section' : isOfficialSection ? 'official-section' : undefined,
      }),
    );
};

const getOmniSearch = async ({ jwtToken, name }: SearcherProps): Promise<SearcherResponse[]> => {
  const staffRoles = [
    'Maestro',
    'Director',
    'Super',
    'Administrador',
    'Teacher',
    'Principal',
    'Student',
    'Administrator',
  ];
  const response = await searchOmni(jwtToken, name);
  return response
    .filter(({ userPk }) => userPk !== undefined)
    .map(({ userPk = 0, contentType, firstName = '', lastName = '', role = '' }: OmniSearch) => ({
      value: userPk,
      contentType,
      label: `${firstName} ${lastName}`,
      subLabel: role,
      // These texts are harded from the backend
      groupType: staffRoles.includes(role) ? 'staff' : 'person',
    }));
};

interface SearhTypeaheadProps {
  searchType: 'structure' | 'person';
  onSearchResult: (result: SearcherResponse) => void;
}

export const SearchTypeahead = ({ searchType, onSearchResult }: SearhTypeaheadProps) => {
  const { session } = useContext<ContextType>(GlobalEdooContext);

  const fetchSearches = useCallback(
    async ({
      name,
      searchFunction,
    }: {
      name: string;
      searchFunction: (props: SearcherProps) => Promise<SearcherResponse[]>;
    }): Promise<OPTION_TYPE[]> => {
      if (!session) return [];
      const jwtToken = session.token;

      try {
        return await searchFunction({ jwtToken, name });
      } catch (responseError) {
        console.log(responseError);
        return [];
      }
    },
    [session],
  );

  return (
    <>
      {session && searchType === 'structure' && (
        <Typeahead
          clearIcon="times"
          fullWidth
          hover="primary"
          icon="search"
          inputDebounce={500}
          items={[]}
          placeholder="Ej. Primero primaria"
          size="small"
          tint="primary"
          onSelect={(selectedItem: OPTION_TYPE | null) => {
            if (selectedItem === null || typeof selectedItem === 'string') return;
            onSearchResult(selectedItem as SearcherResponse);
          }}
          filterItems={false}
          fetchItems={(name: string) => fetchSearches({ name, searchFunction: getStructureSearch })}
          renderItem={(option: OPTION_TYPE, idx: number, itemProps?) => (
            <UserSearchRenderItem
              key={`search-result-element-${idx}`}
              showSubLabel
              option={option}
              idx={idx}
              itemProps={itemProps}
            />
          )}
          renderSelectedItem={(option: OPTION_TYPE, onReset) => {
            option; // Just for not showing error
            setTimeout(() => onReset());
            return <></>;
          }}
        />
      )}

      {session && searchType === 'person' && (
        <Typeahead
          clearIcon="times"
          fullWidth
          hover="primary"
          icon="search"
          inputDebounce={500}
          items={[]}
          placeholder="Ej. Mario Galindo"
          size="small"
          tint="primary"
          onSelect={(selectedItem: OPTION_TYPE | null) => {
            if (selectedItem === null || typeof selectedItem === 'string') return;
            onSearchResult(selectedItem as SearcherResponse);
          }}
          filterItems={false}
          fetchItems={(name: string) => fetchSearches({ name, searchFunction: getOmniSearch })}
          renderItem={(option: OPTION_TYPE, idx: number, itemProps?) => (
            <UserSearchRenderItem showSubLabel option={option} idx={idx} itemProps={itemProps} />
          )}
          renderSelectedItem={(option: OPTION_TYPE, onReset) => {
            option; // Just for not showing error
            onReset();
            return <></>;
          }}
        />
      )}
    </>
  );
};
