import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEdooImporter } from '../../../data/hooks/useEdooImporter';

import { Select, SelectOption } from '@nahualventure/paper-ui';
import ImporterStep, { FormStepProps } from '../ImporterStep';
import { EdooImporterSteps } from '../../../settings';

const ImporterDataType: React.FunctionComponent<FormStepProps> = ({
  isActive = false,
  isDisplaying = false,
}: FormStepProps) => {
  const [selectedOption, setSelectedOption] = useState<SelectOption | undefined>(undefined);
  const { t } = useTranslation();
  const { setStep, setDataType } = useEdooImporter();

  const options: SelectOption[] = [
    {
      value: 'students',
      label: t('EdooImporterDataTypes.students'),
    },
    /* {
      value: 'staffs',
      label: t('EdooImporterDataTypes.staffs'),
    },
    {
      value: 'attitudinals',
      label: t('EdooImporterDataTypes.attitudinals'),
    },
    {
      value: 'levels',
      label: t('EdooImporterDataTypes.levels'),
    },
    {
      value: 'courses',
      label: t('EdooImporterDataTypes.courses'),
    }, */
  ];

  return (
    <ImporterStep
      isActive={isActive}
      isDisplaying={isDisplaying}
      isInline
      title={t('EdooImporter.dataType')}
    >
      <Select
        options={options}
        placeholder={t('EdooImporter.selectDataType')}
        size="medium"
        value={selectedOption}
        onChange={(option: SelectOption) => {
          setSelectedOption(option);
          setDataType && setDataType(option.value);
          setStep && setStep(EdooImporterSteps.DataType + 1);
        }}
      />
    </ImporterStep>
  );
};

export default ImporterDataType;
