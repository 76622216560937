import { Stages } from '../api';
import { toSnakeCase, parseBoolToFormBooleans } from '../utils';

// School config === Stage config (at this moment, so we can reuse our helpers...)
import {
  AsyncFunction,
  SchoolConfigResponse,
  SaveSchoolConfigRequest,
  SaveSchoolConfigResponse,
} from './types';

export const fetchStageConfigWithMeta: AsyncFunction<any, SchoolConfigResponse> = async (
  id: number,
  jwtToken: string,
) => {
  return await Stages.custom.getConfigWithMeta({ id, token: jwtToken });
};

export const saveStageConfiguration: AsyncFunction<
  SaveSchoolConfigRequest,
  SaveSchoolConfigResponse
> = async (request: SaveSchoolConfigRequest) => {
  const snakeCaseData = {
    ...toSnakeCase(request.config),
  };

  // Django receives this format for School Config: { 'show_personal_data_update': ['on'], etc... }
  // So, we need to parse all booleans to string array
  const data = parseBoolToFormBooleans(snakeCaseData);

  const { id, jwtToken } = request;
  return await Stages.custom.saveStageConfiguration({ id, token: jwtToken, data });
};
