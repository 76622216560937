import React, { createContext } from 'react';

type MultipageRouterContextProviderProps = {
  children: React.ReactNode;
  path: string;
};

export interface MultipageRouterContextType {
  path: string;
}

export const MultipageRouterContext = createContext<MultipageRouterContextType>({ path: '' });

const MultipageRouterContextProvider: React.FunctionComponent<
  MultipageRouterContextProviderProps
> = ({ path, children }: MultipageRouterContextProviderProps) => (
  <MultipageRouterContext.Provider value={{ path }}>{children}</MultipageRouterContext.Provider>
);

export default MultipageRouterContextProvider;
